/** @module paper */

import React, { useEffect, useRef } from 'react';
import { findNode } from './dom-util.js';

// -----------------------------------------------------------------------------
//    useClickOutside
// -----------------------------------------------------------------------------

/**
 * Registers a callback function to be invoked if the user clicks outside of a particular {@link HTMLElement}.
 *
 * @param {:~Ref} containerRef - A reference to the container.
 * @param {Event} [onClickOutside] - A callback function invoked if the user clicks outside of the specified container.
 * @hook
 */
export function useClickOutside(containerRef, onClickOutside)
{
  const handlerRef = useRef();

  const clicked = evt =>
  {
    for (let target = evt.target; target; target = target.parentNode)
    {
      if (target === containerRef.current)
        return;
    }
    if (handlerRef.current)
      handlerRef.current(evt);
  };

  useEffect(() =>
  {
    handlerRef.current = onClickOutside;
  }, [ onClickOutside ]);

  useEffect(() =>
  {
    document.addEventListener('click', clicked, { capture: true });
    return () => document.removeEventListener('click', clicked, { capture: true });
  }, [ ]);
}

// -----------------------------------------------------------------------------
//    useTimeout
// -----------------------------------------------------------------------------

/**
 * Starts a one-shot timer associated with the calling component.
 *
 * The timer is automatically stopped if the calling component is unmounted before the timer expires.
 *
 * The timeout can be changed on a subsequent render to clear the timer (a `null` timeout) or restart the timer
 * with a new timeout.
 *
 * @param {function} callback - The callback function invoked when the timer expires.
 * @param {?number} millis - The timeout in milliseconds. If `null`, the timer is stopped (or never started in the first place).
 * @param {Array} [reset=[\]] - An optional array of values. If the value of any item in the array changes on a subsequent
 *   render, the timer is restarted.
 * @hook
 */
export function useTimeout(callback, millis, reset=[])
{
  const latestCallback = useRef();

  useEffect(() =>
  {
    latestCallback.current = callback;
  }, [ callback ]);

  useEffect(() =>
  {
    if (millis !== null)
    {
      let timer = setTimeout(() => latestCallback.current(), millis);
      return () => clearTimeout(timer);
    }
  }, [ millis, ...reset ]);
}

// -----------------------------------------------------------------------------
//    useInterval
// -----------------------------------------------------------------------------

/**
 * Starts a repeating timer associated with the calling component.
 *
 * The timer is automatically stopped if the calling component is unmounted.
 *
 * The interval can be changed on a subsequent render to clear the timer (a `null` interval) or restart the timer
 * with a new interval.
 *
 * @param {function} callback - The callback function invoked after each interval.
 * @param {?number} millis - The interval in milliseconds. If `null`, the timer is stopped (or never started in the first place).
 * @hook
 */
export function useInterval(callback, millis, initialMillis=millis)
{
  const latestCallback = useRef();

  useEffect(() =>
  {
    latestCallback.current = callback;
  }, [ callback ]);

  useEffect(() =>
  {
    if (millis !== null)
    {
      let timer = setInterval(() => latestCallback.current(), millis);
      return () => clearInterval(timer);
    }
  }, [ millis, initialMillis ]);
}

// -----------------------------------------------------------------------------
//    useResizeObserver
// -----------------------------------------------------------------------------

/**
 * Registers a callback function to be invoked when an element is resized.
 *
 * @param {?HTMLElement} element - The element to monitor. If `null`, monitoring is discontinued.
 * @param {function} [callback] - The callback function invoked when the element is resized.
 * @hook
 */
export function useResizeObserver(element, callback)
{
  const latestCallback = useRef();
  const lastSize = useRef();

  useEffect(() =>
  {
    latestCallback.current = callback;
  }, [ callback ]);

  useEffect(() =>
  {
    if (! element)
      return;
    if (window.ResizeObserver)
    {
      const observer = new ResizeObserver(entries =>
      {
        if (latestCallback.current)
          latestCallback.current();
      });
      observer.observe(element);
      return () => observer.disconnect();
    }
    const cached = lastSize.current = { width: element.offsetWidth, height: element.offsetHeight };
    const handler = () =>
    {
      if (element.offsetWidth !== cached.width || element.offsetHeight !== cached.height)
      {
        cached.width = element.offsetWidth;
        cached.height = element.offsetHeight;
        if (latestCallback.current)
          latestCallback.current();
      }
    };

    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, [ element ]);
}

// -----------------------------------------------------------------------------
//    useInitFocus
// -----------------------------------------------------------------------------

/**
 * Sets focus to the first {@link HTMLElement} found with a `tabIndex` attribute of `0`.
 *
 * @returns {:~Ref} - A reference that should be attached to the root element of the search.
 * @hook
 */
export function useInitFocus()
{
  const nodeRef = useRef();

  useEffect(() =>
  {
    let node = findNode(nodeRef.current, node => node.tabIndex === 0);
    if (node !== null)
      setTimeout(() => node.focus(), 0);
  }, [ ]);

  return nodeRef;
}

// -----------------------------------------------------------------------------
//    useEventTarget
// -----------------------------------------------------------------------------

/**
 * Adds an event listener that is automatically removed when the calling component is unmounted.
 *
 * Note that this hook only accepts event callback functions; {@link :event~Listener listener objects}
 * are not supported.
 *
 * @param {?:event.EventTarget} [target=null] - The event target, or `null` to remove the event handler.
 * @param {string} [eventName] - The name of the event. Required when a non-null event target is passed.
 * @param {:event~Callback} [callback] - The callback for the event. Required when a non-null event target is passed.
 * @hook
 */
export function useEventTarget(target=null, eventName, callback)
{
  const callbackRef = useRef();

  useEffect(() =>
  {
    callbackRef.current = callback;
  }, [ callback ]);

  useEffect(() =>
  {
    if (target)
    {
      const eventListener = evt => callbackRef.current(evt);
      target.addEventListener(eventName, eventListener);
      return () => target.removeEventListener(eventName, eventListener);
    }
  }, [ target, eventName ] );
}
