import { create } from '@ocsoft/sync-object';
import { useSyncList, useSyncListItem } from '@ocsoft/sync-react';
import { useSearch } from '@ocsoft/paper';

// -----------------------------------------------------------------------------
//    Hooks
// -----------------------------------------------------------------------------

export function useSearchableSpamList()
{
  const found = useSearch();
  return useSyncList('/api/spam', record => found(record.key) || found(record.action) || found(record.comment), [ found ]);
}

export function useSpam(id)
{
  return useSyncListItem('/api/spam', record => record.id === id, [ id ]);
}

// -----------------------------------------------------------------------------
//    Utilities
// -----------------------------------------------------------------------------

export function createSpam(props)
{
  return create('/api/spam', props);
}
