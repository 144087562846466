/** @module chassis */

import React from 'react';
import { SearchInput } from '@ocsoft/paper';
 
import styles from './search-box.css';

/**
 * A header bar search box.
 *
 * @example
 * <Application>
 *   <CommonHeader>
 *     <SearchBox />
 *   </CommonHeader>
 * </Application>
 *
 * @component
 */
export function SearchBox()
{
  return (
    <SearchInput styleName="search" type="text" placeholder="Search..." />
  );
}
