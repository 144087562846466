/** @module paper */

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTimes as closeIcon } from '@fortawesome/pro-regular-svg-icons';

import styles from './window.css';

// -----------------------------------------------------------------------------
//    TitledWindow
// -----------------------------------------------------------------------------

/**
 * Window chrome that includes a title bar, rounded corners, and a drop shadow.
 *
 * The title bar can be configured to include a close icon.
 *
 * This component is often used when calling `Prompt`'s {@link :~Prompt#modal} method.
 *
 * @prop {:~Element} title - The title bar content.
 * @prop {string} [width='75vw'] - The window's width.
 * @prop {string} [height='auto'] - The window's height.
 * @prop {string} [bodyClass] - An optional CSS class name to use for the body element. The default simply adds a `16px`
 *   margin to all four sides.
 * @prop {EventHandler} [onClose] - An optional click handler. If provided, a close icon is added to the title bar, and the
 *   handler is invoked if the icon is clicked.
 * @prop {:~Element} children - The window body.
 * @component
 */
export function TitledWindow({ title, bodyClass=styles.body, width='75vw', height='auto', onClose, children })
{
  return (
    <div styleName='window' style={{ width, height }}>
      <div styleName="header">
        <div styleName='title'>{ title }</div>
        { onClose && <FontAwesomeIcon icon={closeIcon} styleName="close" onClick={onClose} /> }
      </div>
      <div className={bodyClass}>
        { children }
      </div>
    </div>
  );
}
