import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Timestamp } from '@ocsoft/time';
import { Button, ButtonGroup, usePrompt, usePromptWindow, TitledWindow } from '@ocsoft/paper';

import { faExclamationCircle as icon } from '@fortawesome/pro-duotone-svg-icons';
import styles from './fault.css';

// -----------------------------------------------------------------------------
//    Fault
// -----------------------------------------------------------------------------

export function Fault({ fault })
{
  const prompt = usePrompt();

  const details = () =>
  {
    prompt.modal(<FaultWindow fault={fault} />);
  };

  return (
    <div styleName="fault">
      <FontAwesomeIcon icon={icon} styleName='icon' />
      <div styleName="info">
        <div styleName="message">{ fault.message }</div>
        <div styleName="extra">
          <span>Since { Timestamp.of(fault.created).conciseString }</span><a role="button" onClick={details}>(Details...)</a>
        </div>
      </div>
    </div>
  );
}

// -----------------------------------------------------------------------------
//    FaultWindow
// -----------------------------------------------------------------------------

function FaultWindow({ fault })
{
  const window = usePromptWindow();

  return (
    <TitledWindow title="Fault Details">
      <div styleName="details">
        <div styleName="label">Message:</div>
        <div styleName="value">{ fault.message }</div>
        <div styleName="label">Created:</div>
        <div styleName="value">{ Timestamp.of(fault.created).conciseString }</div>
        <div styleName="label">Last Updated:</div>
        <div styleName="value">{ Timestamp.of(fault.updated).conciseString }</div>
        <div styleName="label">Error:</div>
        <div styleName="pre-value">{ fault.error ? fault.error.stack : "" }</div>
      </div>
      <ButtonGroup justify="center">
        <Button type="primary" onClick={() => window.close()}>Close</Button>
      </ButtonGroup>
    </TitledWindow>
  );
}
