/** @module paper */

import React, { useContext, useState, useRef, useEffect } from 'react';
import { ListMeta, pick } from '@ocsoft/form-util';
import { FormContext } from './form-context.js';
import { Field } from './field.js';
import { Fader } from './fader.js';
import { Item } from './item.js';
import { useTheme } from './paper.js';

import styles from './drop-list.css';

// -----------------------------------------------------------------------------
//    DropList
// -----------------------------------------------------------------------------

/**
 * A drop down list field.
 *
 * Depending on the specified `meta` property, a single item or multiple items may be selected from the list.
 *
 * This component may only be used within a {@link :.Form}.
 *
 * Other available fields that render lists are {@link :.List} and {@link :.RadioGroup}.
 *
 * @prop {:~FieldName} name - The field name.
 * @prop {string} label - The field label.
 * @prop {:form-util~Adapter} [adapter] - An optional value adapter.
 * @prop {:form-util.ListMeta | Object} meta - The list items and permitted selection type. This may be a ListMeta
 *   instance or an anonymous object containing properties to pass to ListMeta's constructor.
 * @prop {boolean} [hideLabel=false] - Whether to hide the field label.
 * @prop {number} [minWidth=200] - The minimum width of the field, in pixels.
 * @prop {boolean} [grow=false] - Whether to expand the width of the field as much as possible. This option only applies
 *   when multiple fields are placed in a row; a field in a row by itself always grows to full width.
 * @component
 */
export function DropList({ name='', label='', adapter=null, hideLabel=false, meta, minWidth=200, grow })
{
  const listMeta = ListMeta.of(meta);
  const { data, setData, submitting } = useContext(FormContext);
  const [ open, setOpen ] = useState(false);
  const [ cursor, setCursor ] = useState(null);
  const selected = pick(data, name, [], adapter);
  const selectedLabel = listMeta.labelOf(selected);
  const single = listMeta.type === 'single';

  const theme = useTheme();
  const windowHeight = theme.getPixelLength('--list-window-height', 240);
  const itemHeight = theme.getPixelLength('--list-item-height', 24);
  const listRef = useRef();

  const maybeChanged = newSelected =>
  {
    if (! listMeta.isEqualSelection(selected, newSelected))
      setData(name, newSelected, adapter);
  };

  const toggle = (value, close) =>
  {
    maybeChanged(listMeta.toggle(selected, value));
    if (close)
      setOpen(false);
  };

  const scrollTo = value =>
  {
    if (open)
    {
      let index = listMeta.indexOf(value);
      if (index >= 0)
      {
        let node = listRef.current;
        let y = itemHeight * index;
        if (y < node.scrollTop)
          node.scrollTop = y;
        else if ( y >= node.scrollTop + windowHeight)
          node.scrollTop = Math.max(0, y - windowHeight + itemHeight);
      }
    }
  };

  const moveCursor = value =>
  {
    setCursor(value);
    scrollTo(value);
  };

  useEffect(() =>
  {
    if (open)
      scrollTo(listMeta.firstSelected(selected));
  }, [ open ]);

  const toggleOpen = evt =>
  {
    if (! submitting)
      setOpen(! open);
  };

  const close = evt => setOpen(false);

  const keyDown = evt =>
  {
    switch (evt.key)
    {
      case ' ':
        if (! open)
          setOpen(true);
        else if (! single && cursor !== null)
          toggle(cursor);
        break;

      case 'Enter':
        if (! open)
          break;
        if (! single)
        {
          evt.preventDefault();
          if (cursor !== null)
            toggle(cursor);
        }
        close();
        return false;

      case 'Escape':
        if (! open)
          return;
        close();
        break;

      case 'ArrowLeft':
        if (single)
          maybeChanged(listMeta.prev(selected));
        else if (open && cursor !== null)
          toggle(cursor);
        break;

      case 'ArrowRight':
        if (single)
          maybeChanged(listMeta.next(selected));
        else if (open && cursor !== null)
          toggle(cursor);
        break;

      case 'ArrowUp':
        setOpen(true);
        if (single)
        {
          if (! open)
            setOpen(true);
          else
          {
            let value = listMeta.prev(selected);
            maybeChanged(value);
            scrollTo(value);
          }
        }
        else
          moveCursor(listMeta.prev(cursor));
        break;

      case 'ArrowDown':
        setOpen(true);
        if (single)
        {
          if (! open)
            setOpen(true);
          else
          {
            let value = listMeta.next(selected);
            maybeChanged(value);
            scrollTo(value);
          }
        }
        else
          moveCursor(listMeta.next(cursor));
        break;

      default:
        return;
    }
    evt.preventDefault();
  };

  return (
    <Field name={name} label={label} hideLabel={hideLabel} onBlur={close} onKeyDown={keyDown} grow={grow}>
      <div styleName="list-container" style={{ minWidth }} tabIndex="0">
        <span styleName={submitting ? 'disabled-list' : 'list'} onClick={toggleOpen}>
          { selectedLabel }
        </span>
        <Fader in={open} enterDuration={100} exitDuration={200}>
          <div ref={listRef} styleName='popper' style={{ minWidth, maxHeight: `${windowHeight}px` }}>
          {
            listMeta.render((value, key, label) =>
              <Item type={listMeta.type} cursor={cursor === value} selected={listMeta.isSelected(selected, value)}
                    onClick={() => toggle(value, single)} key={key}>
                { label }
              </Item>
            )
          }
          </div>
        </Fader>
      </div>
    </Field>
  );
}
