import React, { useState } from 'react';
import { navigate } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Timestamp } from '@ocsoft/time';
import { urlOf } from '@ocsoft/sync-object';
import { useSorter } from '@ocsoft/sync-react';
import { useSearchText, IconButton, useLocalStorage } from '@ocsoft/paper';
import { PageHeader, PageContent, LogHeader, LogTimestamp, EmptySection, Loading } from '@ocsoft/chassis';
import { useSearchableSpamList } from './spam-util.js';

import { faSkullCrossbones as pageIcon } from '@fortawesome/pro-duotone-svg-icons';
import { faChevronUp as sortUpIcon, faChevronDown as sortDownIcon,
         faPlusCircle as addIcon } from '@fortawesome/pro-solid-svg-icons';

import styles from './spam-list-page.css';

const actionLabels =
{
  accept:       "Accept",
  reject:       "Reject"
};

// -----------------------------------------------------------------------------
//    SpamListPage
// -----------------------------------------------------------------------------

export default function SpamListPage()
{
  const searchText = useSearchText();
  const [ records, status ] = useSearchableSpamList();
  const [ sortMode, setSortMode ] = useLocalStorage('spam-sort-mode', "-+created");
  const sorter = useSorter(sortMode);

  const clicked = record =>
  {
    navigate(`/spam/${record.id}`);
  };

  const newRecord = () => navigate(`/newspam`);

  return (
    <PageContent>
      <LogHeader icon={pageIcon} title={searchText === '' ? "All Spam Records" : `Spam Records Matching "${searchText}"`}>
        <IconButton icon={addIcon} styleName="add-icon" title="Add New Record" onClick={newRecord} />
      </LogHeader>
      <ColumnHeaders sortMode={sortMode} onResort={setSortMode} />
      { status === 'loaded' && records.length === 0 && <EmptySection>None found.</EmptySection> }
      { status === 'loading' && <Loading /> }
      {
        records.sort(sorter).map(record =>
          <Spam key={urlOf(record)} record={record} onClick={() => clicked(record)} />)
      }
    </PageContent>
  );
}

function Spam({ record, onClick })
{
  return (
    <div styleName="record" onClick={onClick}>
      <div styleName="key">{ record.key }</div>
      { record.status === 'active' && <div styleName="action">{ actionLabels[record.action] ?? record.action }</div> }
      { record.status !== 'active' && <div styleName="disabled">Disabled</div> }
      <div styleName="priority">{ record.priority }</div>
      <div styleName="comment">{ record.comment }</div>
      <div styleName="ts">{ Timestamp.of(record.created).localDate.conciseString }</div>
    </div>
  );
}

function ColumnHeaders({ sortMode, onResort })
{
  return (
    <div styleName="column-headers">
      <ColumnHeader label="Regex" name="key" sortMode={sortMode} onResort={onResort} />
      <ColumnHeader label="Action" name="dest" sortMode={sortMode} onResort={onResort} />
      <ColumnHeader label="Priority" name="+priority" sortMode={sortMode} onResort={onResort} />
      <ColumnHeader label="Notes" name="comment" sortMode={sortMode} onResort={onResort} />
      <ColumnHeader label="Created" name="+created" sortMode={sortMode} rightAlign={true} onResort={onResort} />
    </div>
  );
}

function ColumnHeader({ label, name, sortMode, rightAlign=false, onResort })
{
  const descending = sortMode[0] === '-';
  const sortName = descending ? sortMode.substring(1) : sortMode;
  const resort = () => onResort(sortName !== name ? name : `${descending ? '' : '-'}${name}`);

  return (
    <div styleName={rightAlign ? 'column-right' : 'column'} onClick={resort}>
      { label }
      { sortName === name && <FontAwesomeIcon icon={descending ? sortDownIcon : sortUpIcon} styleName="sort-icon" /> }
   </div>
  );
}
