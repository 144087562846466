/** @module paper */

import React, { useEffect, useContext, useRef } from 'react';
import defaultTheme from './themes/default.js';

/**
 * A wrapper for {@link :~ThemeData} that includes some useful utility methods.
 *
 * @inner
 */
export class Theme
{
  constructor(data={})
  {
    /**
     * The theme data.
     *
     * @type {:~ThemeData}
     * @readonly
     */
    this.data = data;
  }

  /**
   * Gets a value from the theme given its key.
   *
   * @param {string} key - The theme key.
   * @param {*} [defaultValue] - The value to return if the theme does not have the specified key.
   * @returns {*} The value.
   */
  get(key, defaultValue)
  {
    let value = this.data[key];
    return value !== undefined ? value : defaultValue;
  }

  /**
   * Gets a numeric value (in pixels) from the theme given its key.
   *
   * @param {string} key - The theme key.
   * @param {number} [defaultValue=0] - The value to return if the theme does not have the specified key.
   * @returns {number} The value.
   */
  getPixelLength(key, defaultValue=0)
  {
    let value = this.data[key];
    return value !== undefined ? parseInt(value) : defaultValue;
  }

  /**
   * Creates a new theme by merging this theme with the specified theme data.
   *
   * @param {:~ThemeData} data - The other theme data.
   * @returns {:~Theme} The merged theme.
   */
  merge(data)
  {
    return new Theme({ ...this.data, ...data });
  }
}

const ThemeContext = React.createContext(new Theme());

/**
 * Establishes the theme used by Paper components that appear lower in the tree.
 *
 * An instance of this component must be an ancestor of any other component or hook used from the Paper library.
 *
 * @prop {:~ThemeData} [theme] - The theme to apply to the components under this element. If omitted, the default
 *   theme is used.
 *
 * @example
 * <Paper>
 *   <Alert type="success" caption="This should be styled correctly." />
 * </Paper>
 * @component
 * @static default
 */
export default function Paper({ theme=defaultTheme, children })
{
  const node = useRef(null);

  useEffect(() =>
  {
    for (const [key, value] of Object.entries(theme))
    {
      if (key.startsWith("--"))
        node.current.style.setProperty(key, value);
    }

    return () =>
    {
      for (const key of Object.keys(theme))
      {
        if (key.startsWith("--"))
          node.current.style.removeProperty(key);
      }
    };
  }, [ theme ]);

  const api = new Theme(theme);

  return (
    <ThemeContext.Provider value={api}>
      <div ref={node}>
        { children }
      </div>
    </ThemeContext.Provider>
  );
}

/**
 * Gets the theme that is active for the component that calls this hook.
 *
 * @returns {:~Theme} The active theme.
 * @hook
 */
export function useTheme()
{
  return useContext(ThemeContext);
}

/**
 * A dictionary object that maps theme keys to values.
 *
 * Keys that prefixed with a double hyphen (e.g. `'--button-font'`) become CSS custom properties that can be used
 * to style Paper components. All other keys are only accessible to components using the {@link :~Theme} API.
 *
 * See [MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties) for more information about
 * CSS custom properties.
 *
 * @type {Object}
 * @typedef :~ThemeData
 */
