/** @module paper */

import React, { useRef } from 'react';

import styles from './button.css';

// -----------------------------------------------------------------------------
//    Button
// -----------------------------------------------------------------------------

/**
 * A button with rounded rectangle chrome rendered in one of five standard colors.
 *
 * @example
 * <Button type="danger" onClick={deleteItem}>Delete Item</Button>
 *
 * @prop {:.Button~Type} [type='primary'] - The button type.
 * @prop {*} children - The foreground of the button, typically text.
 * @prop {function} [onClick=null] - The click handler.
 * @prop {boolean} [delay=false] - If `true`, the click handler is not invoked until the button has finished animating;
 *   if `false`, the handler is invoked as soon as the button is pressed.
 * @prop {boolean} [busy=false] - If `true`, button presses are ignored, and the wait cursor is displayed.
 * @prop {number | string} [width] - An optional explicit width for the button. If this is a string (e.g. `'20rem'`), it is
 *   used as-is. If this is a number, it is assumed to be in pixel units. If omitted, the button is sized automatically.
 * @prop {...*} [props] - Any additional properties are applied to the underlying HTML `Button` element.
 * @component
 */
export function Button({ type='primary', children, onClick=null, delay=false, busy=false, width, ...props })
{
  const nodeRef = useRef(null);

  let clicked = evt =>
  {
    nodeRef.current.classList.add(styles.pressed);
    nodeRef.current.addEventListener('animationend', () =>
    {
      if (nodeRef.current !== null)
        nodeRef.current.classList.remove(styles.pressed);
      if (onClick !== null && ! busy && delay)
        onClick(evt);
    }, { once: true });
    if (! delay && ! busy && onClick !== null)
      onClick(evt);
  };

  if (typeof width === 'number')
    width = `${width}px`;

  return (
    <button type="button" ref={nodeRef} className={styles[type]}
            style={{ width, cursor: busy ? 'progress' : undefined }}
            onClick={clicked} { ...props }>
      { children }
    </button>
  );
}

// -----------------------------------------------------------------------------
//    ButtonRow
// -----------------------------------------------------------------------------

/**
 * A horizontal row of buttons.
 *
 * @example
 * <ButtonRow justify="center">
 *   <Button>OK</Button>
 *   <Button>Cancel</Button>
 * </ButtonRow>
 *
 * @prop {string} [justify='left'] - The button justification: `'left'`, `'center'`, or `'right'`.
 * @prop {number | string} [gapAbove=0] - The amount of empty space to reserve above the button row — in pixels if the value
 *   is numeric, or any arbitrary CSS units if the value is a string.
 * @prop {*} children - The buttons to include in the row.
 * @component
 */
export function ButtonRow({ children, justify='left', gapAbove=0 })
{
  if (typeof gapAbove === 'number')
    gapAbove = `${gapAbove}px`;

  return (
    <div className={styles[`group-${justify}`]} style={{ marginTop: gapAbove }}>
      { children }
    </div>
  );
}

// -----------------------------------------------------------------------------
//    ButtonGroup
// -----------------------------------------------------------------------------

/**
 * A horizontal row of buttons.
 *
 * This component is simply a version of {@link :.ButtonRow} that defaults to a 16 pixel gap above the row.
 *
 * @prop {string} [justify='left'] - The button justification: `'left'`, `'center'`, or `'right'`.
 * @prop {number | string} [gapAbove=16] - The amount of empty space to reserve above the button row — in pixels if the value
 *   is numeric, or any arbitrary CSS units if the value is a string.
 * @prop {*} children - The buttons to include in the row.
 * @component
 */
export function ButtonGroup({ children, justify='left', gapAbove=16 })
{
  return (
    <ButtonRow justify={justify} gapAbove={gapAbove}>
      { children }
    </ButtonRow>
  );
}

// -----------------------------------------------------------------------------
//    ButtonColumn
// -----------------------------------------------------------------------------

/**
 * A vertical column of buttons.
 *
 * Each button is expanded to the width of the widest button in the column.
 *
 * @prop {string} [justify='top'] - The button justification: `'top'`, `'center'`, or `'bottom'`.
 * @prop {string} [minWidth='auto'] - The minimum width of the column.
 * @prop {*} children - The buttons to include in the column.
 * @component
 */
export function ButtonColumn({ children, justify='top', minWidth='auto' })
{
  return (
    <div className={styles[`column-${justify}`]} style={{ minWidth }}>
      { children }
    </div>
  );
}

/**
 * Standard button types.
 *
 * @enum :.Button~Type
 * @case primary - A button that performs the most common action when grouped with other buttons.
 * @case secondary - A button that performs a less common action when grouped with other buttons.
 * @case warning - A button that performs an action that requires extra attention from the user.
 * @case danger - A button that performs a potentially destructive action, such as deleting an object.
 * @case success - A button that performs a positive action, such as creating an object.
 */
