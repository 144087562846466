export default
{
  // ----- Styles with leading dashes are available in CSS variables AND in JavaScript via the theme API. -----

  "--button-font":                  "14px Helvetica Neue, Helvetica, Sans-Serif",
  "--button-border-radius":         "8px",
  "--button-padding":               "6px 16px",
  "--disabled-opacity":             0.4,

  "--field-height":                 "16px",
  "--field-value-font":             "14px Helvetica Neue, Helvetica, Sans-Serif",
  "--field-label-font":             "12px Futura, Helvetica Neue, Helvetica, Sans-Serif",
  "--field-error-font":             "12px Helvetica Neue, Helvetica, Sans-Serif",
  "--field-vertical-spacing":       "20px",
  "--field-group-spacing":          "20px",
  "--cursor-color":                 "#dd8c",
  "--radio-button-spacing":         "16px",
  "--tag-font":                     "11px Futura, Helvetica Neue, Helvetica, Sans-Serif",
  "--tag-color":                    "#aac",
  "--tag-text-color":               "white",

  "--calendar-header-font":         "500 16px Helvetica Neue, Helvetica, Sans-Serif",
  "--calendar-cell-selected-bg":    "khaki",
  "--calendar-cell-today-bg":       "#ddd",

  "--alert-font":                   "14px Helvetica Neue, Helvetica, Sans-Serif",
  "--alert-padding":                "10px",
  "--inline-alert-padding":         "10px 32px 10px 16px",
  "--alert-border-radius":          "8px",
  "--success-alert-bg":             "#dff0d8",
  "--success-alert-text-color":     "#3c763d",
  "--success-alert-box-shadow":     "3px 3px 6px #3c763d",
  "--success-inline-box-shadow":    "1px 1px 2px #3c763d",
  "--info-alert-bg":                "#d9edf7",
  "--info-alert-text-color":        "#31708f",
  "--info-alert-box-shadow":        "3px 3px 6px #31708f",
  "--info-inline-box-shadow":       "1px 1px 2px #31708f",
  "--error-alert-bg":               "#f2dede",
  "--error-alert-text-color":       "#a94442",
  "--error-alert-box-shadow":       "3px 3px 6px #a94442",
  "--error-inline-box-shadow":      "1px 1px 2px #a94442",
  "--warning-alert-bg":             "#fcf8e3",
  "--warning-alert-text-color":     "#8a6d3b",
  "--warning-alert-box-shadow":     "3px 3px 6px #8a6d3b",
  "--warning-inline-box-shadow":    "1px 1px 2px #8a6d3b",
  "--list-item-height":             "30px",
  "--list-window-height":           "210px",


  // ----- Styles without leading dashes are ONLY available in JavaScript via the theme API. -----

  "graph":
  {
    "axisLabel":
    {
      "font": "12px sans-serif",
      "fillStyle": "rgba(40, 40, 120, 0.7)",
      "textAlign": "right",
      "textBaseline": "middle"
    },
    "rightAxisLabel":
    {
      "font": "12px sans-serif",
      "fillStyle": "rgba(0, 90, 0, 0.7)",
      "textAlign": "left",
      "textBaseline": "middle"
    },
    "majorGridLine":
    {
      "strokeStyle": "rgba(0, 0, 0, 0.20)"
    },
    "minorGridLine":
    {
      "strokeStyle": "rgba(0, 0, 0, 0.10)"
    },
    "midnightGridLine":
    {
      "strokeStyle": "rgba(0, 0, 0, 0.30)",
      "dashPattern": [4, 2]
    },
    "plotLine":
    {
      "strokeStyle": "rgb(40, 40, 120)",
      "lineWidth": 1
    },
    "plotFill":
    {
      "fillStyle": "rgba(192, 192, 216, 0.4)"
    },
    "bar":
    {
      "fillStyle": "rgba(160, 160, 192, 0.9)"
    },
    "bar-0":
    {
      "fillStyle": "rgba(160, 160, 255, 0.5)"
    },
    "bar-1":
    {
      "fillStyle": "rgba(160, 160, 255, 0.8)"
    },
    "bar-2":
    {
      "fillStyle": "rgba(95, 95, 223, 0.8)"
    },
    "bar-3":
    {
      "fillStyle": "rgba(79, 79, 191, 0.8)"
    },
    "major-indicator":
    {
      "fillStyle": "rgba(63, 63, 191)"
    },
    "minor-indicator":
    {
      "fillStyle": "rgba(223, 223, 255)"
    },
    "red-indicator":
    {
      "fillStyle": "red"
    },
    "line-0":
    {
      "strokeStyle": "rgb(40, 40, 120)",
      "fillStyle": "rgba(192, 192, 216, 0.4)",
      "lineWidth": 1
    },
    "line-1":
    {
      "strokeStyle": "green",
      "lineWidth": 1
    },
    "line-2":
    {
      "strokeStyle": "blue",
      "lineWidth": 1
    },
    "line-3":
    {
      "strokeStyle": "orange",
      "lineWidth": 1
    },
    "sweeper":
    {
      "strokeStyle": "rgba(0, 0, 0, 0.5)",
      "dashPattern": [4, 2]
    },
    "overlayBox":
    {
      "fillStyle": "rgba(160, 160, 192, 1)"
    },
    "overlayText":
    {
      "font": "12px sans-serif",
      "fillStyle": "white",
      "textAlign": "left",
      "textBaseline": "top",
      "height": 12,
      "xPadding": 8,
      "yPadding": 4
    },
    "overlayAxisBox":
    {
      "fillStyle": "rgba(192, 192, 216, 1)"
    },
    "overlayAxisText":
    {
      "font": "12px sans-serif",
      "fillStyle": "white",
      "textAlign": "right",
      "textBaseline": "middle",
      "height": 12,
      "yPadding": 4
    },
    "overlayRightAxisBox":
    {
      "fillStyle": "rgba(120, 192, 120, 1)"
    },
    "overlayRightAxisText":
    {
      "font": "12px sans-serif",
      "fillStyle": "white",
      "textAlign": "left",
      "textBaseline": "middle",
      "height": 12,
      "yPadding": 4
    }
  }
};
