import React from 'react';
import { Router, Redirect, navigate } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Application, PageIcon, PageSelector, CommonHeader, SearchBox, render } from '@ocsoft/chassis';
import AliasListPage from './alias-list-page.js';
import AliasPage from './alias-page.js';
import SpamListPage from './spam-list-page.js';
import SpamPage from './spam-page.js';

import { faUserTag as aliasIcon, faSkullCrossbones as spamIcon } from '@fortawesome/pro-solid-svg-icons';
import styles from './main.css';

// -----------------------------------------------------------------------------
//    Application
// -----------------------------------------------------------------------------

render(
  <Application withAuthentication>
    <PageSelector>
      <PageIcon icon={aliasIcon} label="Aliases" path="/aliases" match={[ "/aliases", "/aliases/**", "/newalias" ]} />
      <PageIcon icon={spamIcon} label="Spam" path="/spam" match={[ "/spam", "/spam/**", "/newspam" ]} />
    </PageSelector>
    <CommonHeader>
      <SearchBox />
    </CommonHeader>
    <div styleName="content">
      <Router>
        <Redirect from="/" to="/aliases" />
        <AliasListPage path="/aliases" />
        <AliasPage path="/aliases/:aliasKey" />
        <AliasPage path="/newalias" />
        <SpamListPage path="/spam" />
        <SpamPage path="/spam/:id" />
        <SpamPage path="/newspam" />
      </Router>
    </div>
  </Application>
);
