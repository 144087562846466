import React, { createContext, useContext } from 'react';

export const AppContext = createContext(
{
  withFaults:             false,
  withAuthentication:     false
});

export function useAppContext()
{
  return useContext(AppContext);
}
