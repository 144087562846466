/** @module paper */

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './icon.css';

const remoteCache = new Map();

// -----------------------------------------------------------------------------
//    Icon
// -----------------------------------------------------------------------------

/**
 * An icon component that supports and automatically detects the following types of icons:
 *
 * * {@link https://fontawesome.com Font Awesome} icons via {@link https://github.com/FortAwesome/react-fontawesome
 *   react-fontawesome}.
 *
 * * Strings containing SVG documents. These can be imported from `'*.svg'` files during the bundling process using
 *   the {@link https://webpack.js.org/loaders/svg-inline-loader svg-inline-loader} WebPack plugin.
 *
 * * Strings that are URLs to remote images.
 *
 * Font Awesome icons are sized based on the current font size and inherit the color of the parent HTML element.
 * When using a custom SVG icon, however, care must be taken if you want to achieve the same behavior. For color
 * inheritance to work, any `fill` attributes on SVG elements must be removed or set to `currentColor`. Additionally,
 * `width` and `height` attributes should be removed from the top-level `<svg>` element. When doing this,
 * you must ensure that there is a `viewBox` attribute to indicate the size of the canvas to which the SVG's elements
 * are drawn. Note that most optimizers strip the view box when a width and height are present. An example of fixing
 * this would be replacing `width="512" height="512"` with `viewBox="0 0 512 512"`.
 *
 * @prop {?(string | Object)} icon - The icon to display. If `null` or `undefined`, this component does not render anything.
 * @prop {string} [className] - An optional CSS class name used to style the icon.
 * @prop {...*} [props] - Any additional properties are applied to the underlying HTML element.
 * @component
 *
 * @example
 * import myIcon from './my-icon.svg';
 *
 * return <Icon icon={myIcon} />;
 *
 */
export function Icon({ icon, className, ...props })
{
  if (! icon)
    return null;

  if (typeof icon === 'string')
  {
    // Maybe it's an URL to an image.
    if (icon.startsWith('http:') || icon.startsWith('https:'))
      return <RemoteIcon url={icon} className={className} {...props} />

    // Otherwise ssume it's an inline SVG.
    className = className ? `${className} ${styles['svg-container']}` : styles['svg-container'];

    return (
      <span dangerouslySetInnerHTML={{__html: icon}} className={className} { ...props } />
    );
  }

  // Otherwise, assume it's a Font Awesome icon.
  return <FontAwesomeIcon icon={icon} className={className} { ...props } />;
}

function RemoteIcon({ url, className, ...props })
{
  const [ icon, setIcon ] = useState(null);

  url = validateUrl(url);
  const cached = remoteCache.get(url);

  useEffect(() =>
  {
    if (url && ! cached)
    {
      (async () =>
      {
        try
        {
          const res = await fetch(url);
          if (res.ok)
          {
            const data = await res.text();
            setIcon(data);
            remoteCache.set(url, data);
          }
          else
            console.error("Failed to load icon: %s", url);
        }
        catch (err)
        {
          console.error("Failed to load icon: %s: %s", url, err.message);
        }
      })();
    }
  }, [ url, cached ]);

  if (! icon && ! cached)
    return <span className={className} />;
  return <Icon icon={icon ?? cached} className={className} { ...props } />;
}

function validateUrl(url)
{
  if (! url)
    return null;
  const urlObj = new URL(url);
  if (window.location.protocol === 'https:' && urlObj.hostname.endsWith('.net'))
  {
    urlObj.protocol = 'https:';
    urlObj.hostname = urlObj.hostname.slice(0, -3) + 'com';
  }
  return urlObj.href;
}
