/** @module paper */

import React from 'react';
import { Transition } from 'react-transition-group';

/**
 * A wrapper component that shows or hides its content using a fade in/out animation.
 *
 * @prop {boolean} [in=false] - Whether to display the component's children.
 * @prop {number} [duration=250] - The duration of the animation in milliseconds.
 * @prop {number} [enterDuration=null] - An optional separate duration for the fade in animation. If `null`, the generic
 *   `duration` is used.
 * @prop {number} [exitDuration=null] - An optional separate duration for the fade out animation. If `null`, the generic
 *   `duration` is used.
 * @prop {*} children - The element that is either shown or hidden.
 * @prop {...*} props - Additional properties are forwarded to the `Transition` component.
 * @component
 */
export function Fader({ in: visible=false, children, duration=250, enterDuration=null, exitDuration=null, ...props })
{
  if (enterDuration === null)
    enterDuration = duration;
  if (exitDuration === null)
    exitDuration = duration;

  const onEnter = (node, appearing) =>
  {
    node.style.setProperty('opacity', 0);
    node.style.removeProperty('transition');
  };

  const onEntering = (node, appearing) =>
  {
    node && node.scrollTop;                 // Force the transition animation to occur
    node.style.setProperty('opacity', 1);
    node.style.setProperty('transition', `opacity ${enterDuration}ms linear`);
  };

  const onExit = node =>
  {
    node.style.setProperty('opacity', 1);
    node.style.removeProperty('transition');
  };

  const onExiting = node =>
  {
    node && node.scrollTop;                 // Force the transition animation to occur
    node.style.setProperty('opacity', 0);
    node.style.setProperty('transition', `opacity ${exitDuration}ms linear`);
  };

  return (
    <Transition in={visible} timeout={{ enter: enterDuration, exit: exitDuration }}
                onEnter={onEnter} onEntering={onEntering} onExit={onExit} onExiting={onExiting}
                mountOnEnter unmountOnExit { ...props }>
      { children }
    </Transition>
  );
}
