export function findNode(node, predicate)
{
  if (! node || predicate(node))
    return node;
  for (let child of node.children)
  {
    let result = findNode(child, predicate);
    if (result !== null)
      return result;
  }
  return null;
}
