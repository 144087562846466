/** @module paper */

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheckCircle, faInfoCircle, faExclamationCircle, faTimes } from '@fortawesome/pro-regular-svg-icons';
import styles from './alert.css';

const alertTypeIcons =
{
  success:          faCheckCircle,
  info:             faInfoCircle,
  error:            faExclamationCircle,
  warning:          faExclamationCircle
};

// -----------------------------------------------------------------------------
//    Alert
// -----------------------------------------------------------------------------

/**
 * Renders an alert.
 *
 * An alert is simply a rounded box with a caption and optional body. The background and text colors of
 * the alert are determined by the specified alert type. Alerts are typically placed at the top of page to convey
 * important information to the user.
 *
 * If you're looking for an alert that is displayed within its own popup window, see the {@link :~Prompt} class.
 *
 * @prop {string} type - The type of alert: `'success'`, `'info'`, `'error'`, or `'warning'`.
 * @prop {string} caption - The alert caption.
 * @prop {*} [body] - The optional body to display below the caption. This can be a string or component to render. You
 *   can alternatively provide a body as a child element.
 * @component
 *
 * @example
 * <Alert type="error" caption="A temporary network error occurred">
 *   <p>Your changes could not be saved. Please try again later.</p>
 * </Alert>
 */
export function Alert({ type, caption, body, children })
{
  return (
    <div styleName={type}>
      <FontAwesomeIcon icon={alertTypeIcons[type]} styleName="icon" />
      <div styleName='content'>
        <div styleName="caption">{ caption }</div>
        <div styleName="body">{ body || children }</div>
      </div>
    </div>
  );
}
