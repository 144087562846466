/** @module paper */

import React from 'react';
import { Icon } from './icon.js';

import styles from './icon-button.css';

// -----------------------------------------------------------------------------
//    IconButton
// -----------------------------------------------------------------------------

/**
 * A button that consists of a single icon.
 *
 * @example
 * import { faSmile as smileIcon } from '@fortawesome/pro-solid-svg-icons';
 *
 * <IconButton icon={smileIcon} onClick={() => doSomething()} />
 *
 * @prop {string | Object} icon - The imported icon to display, which can be a Font Awesome icon object or an inline SVG string.
 * @prop {function} [onClick=null] - The click handler.
 * @prop {boolean} [disabled=false] - Whether the button is disabled.
 * @prop {boolean} [busy=false] - If `true`, button presses are ignored, and the wait cursor is displayed.
 * @prop {...*} [props] - Any additional properties are applied to the underlying `Icon` component.
 * @component
 */
export function IconButton({ icon, disabled=false, busy=false, onClick, ...props })
{
  let clicked = evt =>
  {
    if (! disabled && ! busy && onClick)
      onClick(evt);
  };

  return (
    <span styleName={disabled ? 'disabled-button' : 'button'} style={{ cursor: busy ? 'progress' : undefined }}
          tabIndex={-1} onClick={clicked}>
      <Icon icon={icon} { ...props } />
    </span>
  );
}
