/** @module chassis */

import React from 'react';
import { createPortal } from 'react-dom';

/**
 * Renders one or more {@link :.PageIcon} components in the header bar.
 *
 * Page icons are used to navigate between pages and are placed starting at the leftmost edge of the header bar.
 *
 * @prop {:paper~Element} children - The content of the common header area.
 * @component
 */
export function PageSelector({ children })
{
  return createPortal(children, document.getElementById('page-selector'));
}
