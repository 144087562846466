/** @module paper */

import React, { useState, useEffect, useRef } from 'react';
import { Overlay } from './overlay.js';

import styles from './autocomplete.css';

// -----------------------------------------------------------------------------
//    AutoComplete
// -----------------------------------------------------------------------------

export function AutoComplete({ text, visible=true, fetcher=null, onText=null, overlaySpacing=22,
                               renderItem=item=>String(item), itemKey=item=>item, children })
{
  const [ items, setItems ] = useState([]);
  const [ selected, setSelected ] = useState(-1);
  const ignoreText = useRef(null);

  const update = (str, items) =>
  {
    if (str === text)
    {
      setItems(items);
      setSelected(-1);
    }
  };

  const report = index =>
  {
    onText(items[index]);
    ignoreText.current = items[index];
    setItems([]);
    setSelected(-1);
  }

  useEffect(() =>
  {
    // This effect runs after render only if `text` changes.

    setSelected(-1);
    let str = text.trim();
    if (str.length !== 0 && fetcher !== null && ignoreText.current !== text)
    {
      let result = Promise.resolve(fetcher(str));
      result.then(value => update(text, Array.isArray(value) ? value : [ ]));
      result.catch(() => update(text, []));
    }
    else
      update(text, []);
    ignoreText.current = null;
  }, [ text ]);

  const onKeyDown = (evt, next) =>
  {
    switch (evt.key)
    {
      case 'ArrowUp':
        evt.preventDefault();
        if (items.length !== 0)
          setSelected(selected === -1 ? items.length : selected - 1);
        break;

      case 'ArrowDown':
        evt.preventDefault();
        if (items.length !== 0)
          setSelected(selected === items.length - 1 ? -1 : selected + 1);
        break;

      case 'Enter':
        if (selected !== -1 && onText !== null)
        {
          evt.preventDefault();
          evt.stopPropagation();
          report(selected);
        }
        break;

      case 'Escape':
        setItems([]);
        setSelected(-1);
        break;

      default:
        if (next)
          next(evt);
        break;
    }
  };

  let show = visible && items.length !== 0 && (items.length > 1 || text !== renderItem(items[0]));

  return (
    <div styleName='container'>
      { children({ onKeyDown }) }
      <Overlay in={show} exitDuration={0} spacing={overlaySpacing}>
        <div styleName='list'>
          { items.map((item, index) => <div styleName={index == selected ? 'selected-item' : 'item'}
                                            key={itemKey(item)} onClick={() => report(index)}>{renderItem(item)}</div>) }
        </div>
      </Overlay>
    </div>
  );
}

/**
 * A function that provides a list of potential matches for the specified text during autocomplete.
 *
 * The function may be synchronous or asynchronous.
 *
 * @param {string} text - The text.
 * @returns {string[]} - The match or matches.
 * @callback :~AutocompleteFetcher
 * @async
 */

/**
 * A callback that renders an autocomplete option.
 *
 * Options are typically strings, but if one has a more complex form, this callback can be used to customize
 * its stringification.
 *
 * @param {*} option - The option.
 * @returns {:~Element} The rendered option.
 * @callback :~AutocompleteRenderFunction
 */

/**
 * A function that determines the effect of a selected autocomplete option on a form's data.
 *
 * This feature can be used to modify multiple fields based on choosing an autocomplete option. For example, clicking on
 * an address in an autocomplete list could fill in the street address, city, state, and zip code fields.
 *
 * @param {*} option - The autocomplete option.
 * @returns {Object} The delta to be merged into the form's data.
 * @callback :~AutocompleteApplicator
 */
