/** @module paper */

import React, { useContext, useRef, useEffect } from 'react';
import { FormContext } from './form-context.js';
import { findNode } from './dom-util.js';

import styles from './field.css';

// -----------------------------------------------------------------------------
//    Field
// -----------------------------------------------------------------------------

/**
 * A field wrapper component.
 *
 * This component is only used when implementing custom fields. It should appear at the top of the field's element
 * tree and takes care of focus management and rendering the field's label, chrome, and potential error message.
 *
 * This component may only be used within a {@link :.Form}.
 *
 * @prop {:~FieldName} name - The field name.
 * @prop {string} label - The field label.
 * @prop {boolean} [hideLabel=false] - Whether to hide the field label.
 * @prop {boolean} [grow=false] - Whether to expand the width of the field as much as possible. This option only applies
 *   when multiple fields are placed in a row; a field in a row by itself always grows to full width.
 * @prop {*} children - The element that renders the main content of the field.
 * @prop {function} [onBlur=null] - A callback invoked when the field loses focus.
 * @prop {function} [onKeyDown=null] - A callback invoked when a key is pressed while the field has focus. If the handler
 *   returns `false`, the field's Enter key handling will be suppressed.
 * @prop {boolean} [submitOnEnter=true] - Whether an Enter keypress submits the form to which the field belongs.
 * @prop {boolean} [alwaysShowError=false] - Whether to show validation errors immediately, without waiting for focus
 *   to be lost.
 * @component
 */
export function Field({ name='', label="", hideLabel=false, grow=false, children, onBlur=null, onKeyDown=null, submitOnEnter=true,
                        alwaysShowError=false })
{
  const context = useContext(FormContext);
  const fieldNode = useRef(null);
  const vars = useRef({ focusDebounceTimer: null, submit: context.submit });

  useEffect(() =>
  {
    vars.current.submit = context.submit;
  }, [ context.submit ]);

  const refocus = () =>
  {
    if (context.focus === name)
    {
      let node = findNode(fieldNode.current, node => node.tabIndex === 0);
      if (node !== null)
        node.focus();
    }
  };

  useEffect(() =>
  {
    // Whenever nodes are added or removed within the field's subtree, and this field is the current field,
    // assign focus to the node that has a tab stop. This is necessary because some of the field types swap
    // tabbable nodes in and out when the user interacts with them.
    let observer = new MutationObserver(mutations => refocus());
    observer.observe(fieldNode.current, { childList: true, subtree: true });
    return () => observer.disconnect();
  }, [ ]);

  const gainedFocus = evt =>
  {
    evt.stopPropagation();
    if (vars.current.focusDebounceTimer)
    {
      clearTimeout(vars.current.focusDebounceTimer);
      vars.current.focusDebounceTimer = null;
    }

    context.setFocus(name);
  };

  const lostFocus = evt =>
  {
    evt.stopPropagation();

    // We want to allow the node tree associated with a field to have multiple focusable elements. The field
    // itself will receive bubbled focus and blur events for its descendants. The challenge is to avoid
    // notifying the form that the field no longer has focus when simply switching between different focus
    // targets within the field's tree. The debounce timer is just an ugly hack to accomplish that.
    if (vars.current.focusDebounceTimer)
      clearTimeout(vars.current.focusDebounceTimer);
    vars.current.focusDebounceTimer = setTimeout(() =>
    {
      context.releaseFocus(name);
      if (onBlur !== null)
        onBlur(evt);
    }, 0);
  };

  const keyDown = evt =>
  {
    if (! context.submitting)
    {
      if (onKeyDown !== null)
      {
        if(onKeyDown(evt) === false)
          return;
      }
      if (submitOnEnter && evt.key === 'Enter')
      {
        // Give MaskedInput time to commit any modified segment values. Example that fails without this:
        // Type 'p' or 'a' to select a.m./p.m. on a TimeField and then press Enter without typing the second
        // letter.
        setTimeout(() => vars.current.submit(), 10);
      }
    }
  };

  let error = alwaysShowError || context.visited[name] ? context.errors[name] : undefined, style;
  if (context.focus === name)
    style = 'focus-line';
  else if (error !== undefined)
    style = 'error-line';
  else
    style = 'line';

  return (
    <div ref={fieldNode} styleName={grow ? 'grow-field' : 'field'} onFocus={gainedFocus} onBlur={lostFocus}
         onKeyDown={keyDown} onClick={refocus}>
      { ! hideLabel && <label styleName="label">{label}</label> }
      { children }
      <div styleName={style} />
      { error && <div styleName="error-message">{error}</div> }
    </div>
  );
}

// -----------------------------------------------------------------------------
//    FieldGroup
// -----------------------------------------------------------------------------

/**
 * A horizontal group of form fields.
 *
 * Normally, fields are arranged one per line in a vertical column. A field group can be used as a hint to
 * display multiple fields on a line. This will only occur on devices with sufficient screen width.
 *
 * This component may only be used within a {@link :.Form}.
 *
 * @example
 * <FieldGroup>
 *   <TextField name="firstName" label="First Name" />
 *   <TextField name="lastName" label="Last Name" />
 * </FieldGroup>
 *
 * @prop {*} children - The fields.
 * @component
 */
export function FieldGroup({ children })
{
  return (
    <div styleName="field-group">
      { children }
    </div>
  );
}

/**
 * A field name.
 *
 * The name of a property within the associated {@link :.Form}'s data. The field will always reflect the current
 * value of the property, and manipulating the field's controls will alter the property accordingly.
 *
 * A field name may include a period (`'.'`) character to access nested objects within the form data. For example,
 * if the form data is `{ "value": 42, user: { id: "bob" } }`, a field can be bound to the user's ID using the
 * name `'user.id'`.
 *
 * @typedef {string} :~FieldName
 */
