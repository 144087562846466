/**
 * A user interface toolkit for React.
 *
 * Paper was originally created for the Planner/Calendar project because a lot of the form field types
 * required to edit events and tasks weren't available in other toolkits or were missing necessary features.
 * The library is still heavily skewed toward forms, but there are also quite a few general purpose widgets
 * such as {@link :paper.Button buttons} and {@link :paper.Graph graphs}, along with an assortment of useful
 * hooks.
 *
 * The components provided by Paper are styled using CSS modules. A rudimentary {@link :paper~Theme theming}
 * system has been built on that foundation, although it is a work in progress; there are still many
 * components using hard-coded fonts, colors, etc.
 *
 * @module paper
 */

export * from './collapser.js';
export * from './fader.js';
export * from './zoomer.js';
export * from './flipper.js';
export * from './detail.js';
export * from './button.js';
export * from './icon-button.js';
export * from './busy-button.js';
export * from './form.js';
export * from './field.js';
export * from './text-field.js';
export * from './radio.js';
export * from './checkbox.js';
export * from './drop-list.js';
export * from './calendar.js';
export * from './date-field.js';
export * from './time-field.js';
export * from './duration-field.js';
export * from './integer-field.js';
export * from './tag-field.js';
export * from './tag.js';
export * from './search.js';
export * from './input.js';
export * from './stack.js';
export * from './prompt.js';
export * from './alert.js';
export * from './hooks.js';
export * from './window.js';
export * from './value.js';
export * from './list.js';
export * from './progress.js';
export * from './graph/index.js';
export * from './storage.js';
export * from './menu.js';
export * from './icon.js';

import Paper from './paper.js';
export default Paper;
export { Theme, useTheme } from './paper.js';

/**
 * A React-compatible element.
 *
 * Allowable types of elements include the following:
 *
 * * Components (e.g. {@link :.Button})
 * * HTML elements, such as `<div>`
 * * Strings and numbers
 * * Arrays of elements
 * * `null` or `undefined` to render nothing at all
 *
 * @typedef :~Element
 */

/**
 * A React-compatible component (e.g. {@link :.Button}).
 *
 * @typedef :~Component
 */

/**
 * The object returned by React's `useRef` hook.
 *
 * @typedef :~Ref
 */

/**
 * A callback function that converts a numeric value to a string.
 *
 * @param {number} value - The value to format.
 * @returns {string} The string.
 * @callback :~NumberFormatter
 */
