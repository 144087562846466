/** @module chassis */

import React from 'react';
import { createPortal } from 'react-dom';

/**
 * Renders one or more elements in the header bar's common area.
 *
 * The common area is the rightmost part of the header bar. Items at that location should apply to all pages. It is
 * typically where the {@link :.SearchBox search box} is placed.
 *
 * Pages that wish to add header bar content specific to the page should use {@link :.PageHeader} instead.
 *
 * @prop {:paper~Element} children - The content of the common header area.
 * @component
 */
export function CommonHeader({ children })
{
  return createPortal(children, document.getElementById('common-header'));
}
