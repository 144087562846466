/** @module chassis */

import React, { useMemo } from 'react';
import { Location, navigate } from '@reach/router';
import { Icon } from '@ocsoft/paper';

import styles from './page-icon.css';

// -----------------------------------------------------------------------------
//    PageIcon
// -----------------------------------------------------------------------------

/**
 * A page selection icon.
 *
 * Clicking on an icon navigates to the specified path (URL). The icon that corresponds to the current path is
 * highlighted.
 *
 * Page icons are generally placed in a {@link :.PageSelector}, although they can be used anywhere in the header bar.
 * 
 * @prop {Object | string} icon - The imported Font Awesome icon object or SVG document string to display.
 * @prop {string} [label] - The optional text to display below the icon.
 * @prop {string} path - The path to which the icon navigates (e.g. `'/shopping/items'`).
 * @prop {string | string[]} [match] - An optional path glob or array of globs. If the current page path matches one
 *   of the globs, the icon is highlighted. If omitted, the icon is only highlighted when the current path exactly matches
 *   the `path` property.
 * @prop {...*} props - Any additional properties are forwarded to the underlying `button`.
 * @component
 */
export function PageIcon({ icon, label, path, match=path, ...props })
{
  const regex = useMemo(() => pathToRegex(match), [ match ]);

  const clicked = () =>
  {
    navigate(path);
  };

  return (
    <Location>
    {
      ({ location }) =>
        <button styleName={regex.test(location.pathname) ? 'selected-button' : 'button'} onClick={clicked} { ...props }>
          <Icon icon={icon} styleName='icon' />
          { label && <span styleName='label'>{ label }</span> }
        </button>
    }
    </Location>
  );
}

function pathToRegex(path)
{
  if (Array.isArray(path))
    path = path.join('|');
  else
    path = String(path);
  return new RegExp(`^${path.replace(/\*/g, '[^/]*')}$`);
}

// -----------------------------------------------------------------------------
//    HeaderIconButton
// -----------------------------------------------------------------------------

/**
 * A header bar icon button.
 *
 * @prop {Object | string} icon - The imported Font Awesome icon object or SVG document string to display.
 * @prop {string} [label] - The optional text to display next to the icon.
 * @prop {boolean} [disabled=false] - Whether the icon button is disabled.
 * @prop {EventHandler} [onClick] - An event handler invoked when the user clicks the button.
 * @prop {...*} props - Any additional properties are forwarded to the underlying `button`.
 * @component
 */
export function HeaderIconButton({ icon, label, disabled=false, onClick, ...props })
{
  return (
    <button styleName='button' onClick={onClick} disabled={disabled} { ...props }>
      <Icon icon={icon} styleName='icon' />
      { label && <span styleName='label'>{ label }</span> }
    </button>
  );
}
// -----------------------------------------------------------------------------
//    HeaderToggleIcon
// -----------------------------------------------------------------------------

/**
 * A header bar icon that can be toggled by clicking it.
 *
 * @prop {Object | string} icon - The imported Font Awesome icon object or SVG document string to display.
 * @prop {string} [label] - The optional text to display next to the icon.
 * @prop {boolean} [select=false] - If `true`, the icon is rendered in the `on` state; if `false`, the icon is rendered
 *   in the `off` state.
 * @prop {EventHandler} [onToggle] - An event handler invoked when the user clicks the button.
 * @prop {...*} props - Any additional properties are forwarded to the underlying `button`.
 * @component
 */
export function HeaderToggleIcon({ icon, label, select, onToggle, ...props })
{
  return (
    <button styleName={select ? 'selected-button' : 'button'} onClick={onToggle} { ...props }>
      <Icon icon={icon} styleName='icon' />
      { label && <span styleName='label'>{ label }</span> }
    </button>
  );
}
