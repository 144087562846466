/** @module paper */

import React, { createContext, useState, useContext, useRef, useEffect } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Paper, { useTheme } from './paper.js';
import { Button, ButtonGroup } from './button.js';
import popoverLightTheme from './themes/popover-light.js';
import popoverDarkTheme from './themes/popover-dark.js';

import { faCheckCircle, faInfoCircle, faExclamationCircle, faTimes } from '@fortawesome/pro-regular-svg-icons';
import styles from './prompt.css';

const alertTypeIcons =
{
  success:          faCheckCircle,
  info:             faInfoCircle,
  error:            faExclamationCircle,
  warning:          faExclamationCircle
};

const WindowContext = createContext(null);

let alertManager = null;

// -----------------------------------------------------------------------------
//    Window
// -----------------------------------------------------------------------------

/**
 * A popup window created by a {@link :~Prompt} method.
 *
 * @inner
 */
class Window extends EventTarget
{
  /**
   * The result committed by the {@link :~Window#cancel} method.
   *
   * @type {*}
   * @default undefined
   */
  cancelResult;

  #result;
  #committed = false;
  #waiters = [ ];

  constructor({ timeout=0 }={})
  {
    super();

    this.node = document.createElement('div');
    this.node.style.position = 'fixed';

    this._closeTimer = null;
    if (timeout)
      this._closeTimer = setTimeout(() => this.cancel(), timeout);
  }

  // Internal API.
  render(element, theme)
  {
    render(
      <Paper theme={theme.data}>
        <WindowContext.Provider value={this}>
          { element }
        </WindowContext.Provider>
      </Paper>,
      this.node
    );
  }

  /**
   * The width of the window (in pixels).
   *
   * @type {number}
   */
  get width()
  {
    return this.node.offsetWidth;
  }

  /**
   * The height of the window (in pixels).
   *
   * @type {number}
   */
  get height()
  {
    return this.node.offsetHeight;
  }

  /**
   * Gets a promise that resolves when a result is {@link :~Window#commit committed}.
   *
   * @type {Promise}
   */
  get result()
  {
    if (this.#committed)
      return Promise.resolve(this.#result);
    return new Promise(resolve => this.#waiters.push(resolve));
  }

  /**
   * Commits a result.
   *
   * This has the effect of closing the window and delivering the result to any callers {@link :~Window#result awaiting} it.
   *
   * @param {*} result - The result.
   */
  commit(result)
  {
    if (! this.#committed)
    {
      this.#committed = true;
      this.#result = result;
      this.close();
      for (let resolve of this.#waiters)
        resolve(this.#result);
      this.#waiters.length = 0;
    }
  }

  /**
   * Commits the {@link :~Window#cancelResult cancel result} and closes the window.
   *
   * The result is delivered to any callers {@link :~Window#result awaiting} it.
   */
  cancel()
  {
    this.commit(this.cancelResult);
  }

  /**
   * Requests that the window be closed.
   *
   * If a {@link :~Window#result} is expected, you should generally use the {@link :~Window#cancel} method instead so that
   * promises are resolved.
   */
  close()
  {
    this.dispatchEvent(new Event('close'));
  }

  // Internal API.
  dispose()
  {
    if (this._closeTimer)
    {
      clearTimeout(this._closeTimer);
      this._closeTimer = null;
    }
    if (this.node)
    {
      unmountComponentAtNode(this.node);
      this.node.remove();
      this.node = null;
      this.dispatchEvent(new Event('dispose'));
    }
  }
}

// -----------------------------------------------------------------------------
//    AlertWindow
// -----------------------------------------------------------------------------

class AlertWindow extends Window
{
  constructor(theme, caption, body, { type='success', timeout, width, onClick })
  {
    super({ timeout });

    this.render(<Alert type={type} caption={caption} body={body} onClick={onClick} />, theme);

    let style = this.node.style;
    style.background = theme.get(`--${type}-alert-bg`, 'green');
    style.color = theme.get(`--${type}-alert-text-color`, 'black');
    style.font = theme.get('--alert-font', '14px sans-serif');
    style.padding = theme.get('--alert-padding', '10px');
    style['border-radius'] = theme.get('--alert-border-radius', '8px');
    style['box-shadow'] = theme.get(`--${type}-alert-box-shadow`, 'unset');
    if (width)
      style.width = width;

    style.right = 0;
    style.bottom = 0;
    style['margin-right'] = '12px';
    style.transform = "translate(0, 100%)";
    style.transition = "transform 250ms ease-out, opacity 250ms ease-out";
  }
}

function Alert({ type, caption, body, onClick })
{
  const window = usePromptWindow();

  const clicked = () =>
  {
    if (onClick)
    {
      window.cancel();
      onClick();
    }
  };

  return (
    <div styleName="alert-main">
      <FontAwesomeIcon icon={alertTypeIcons[type]} styleName="alert-icon" />
      <FontAwesomeIcon icon={faTimes} styleName="close-icon" onClick={() => window.cancel()} />
      <div styleName={onClick ? 'alert-clickable-content' : 'alert-content'} onClick={clicked} >
        <div styleName="alert-caption">{ caption }</div>
        <div styleName="alert-body">{ body }</div>
      </div>
    </div>
  );
}

// -----------------------------------------------------------------------------
//    PopoverWindow
// -----------------------------------------------------------------------------

class PopoverWindow extends Window
{
  constructor(theme, element, { timeout, width, flavor })
  {
    super({ timeout });

    theme = theme.merge(flavor === 'dark' ? popoverDarkTheme : popoverLightTheme);

    let style = this.node.style;
    style.opacity = 0;
    style.transition = "opacity 250ms";
    style.position = 'absolute';
    if (width)
      style.width = width;

    let arrowSize = theme.get('popover-arrow-size', '8px');
    let arrowColor = theme.get('popover-arrow-color', 'gray');

    style['margin-top'] = arrowSize;
    style.background = theme.get(`popover-bg`, 'gray');
    style.color = theme.get('popover-color', 'black');
    style.font = theme.get('popover-font', '14px sans-serif');
    style.padding = theme.get('popover-padding', '10px');
    style['border-radius'] = theme.get('popover-border-radius', '8px');
    style['box-shadow'] = theme.get('popover-box-shadow', 'unset');

    this.arrowNode = document.createElement('div');
    this.addEventListener('dispose', () =>
    {
      this.arrowNode.remove();
      this.arrowNode = null;
    });
    style = this.arrowNode.style;
    style.opacity = 0;
    style.transition = "opacity 250ms";
    style.position = 'absolute';
    style.width = 0;
    style.height = 0;
    style['margin-left'] = `-${arrowSize}`;
    style['border-bottom'] = `${arrowSize} solid ${arrowColor}`;
    style['border-right'] = `${arrowSize} solid transparent`;
    style['border-left'] = `${arrowSize} solid transparent`;

    this.render(element, theme);
  }

  _show(x, y, xArrow)
  {
    this.node.style.left = `${x}px`;
    this.node.style.top = `${y}px`;
    this.node.style.opacity = 1;

    this.arrowNode.style.left = `${xArrow}px`;
    this.arrowNode.style.top = `${y}px`;
    this.arrowNode.style.opacity = 1;

    const clicked = evt =>
    {
      for (let target = evt.target; target; target = target.parentNode)
      {
        if (target === this.node)
          return;
      }
      // The user clicked outside the popover. Close it.
      this.cancel();
      evt.stopPropagation();
    };

    document.addEventListener('click', clicked, { capture: true });
    this.addEventListener('dispose', () =>
    {
      document.removeEventListener('click', clicked, { capture: true });
    });
  }
}

// -----------------------------------------------------------------------------
//    ModalWindow
// -----------------------------------------------------------------------------

class ModalWindow extends Window
{
  constructor(theme, element, { timeout, closeOnBackdropClick=false })
  {
    super({ timeout });

    this.backdrop = document.createElement('div');
    let style = this.backdrop.style;
    style.position = 'fixed';
    style.left = style.top = 0;
    style.width = style.height = '100%';
    style.background = '#000';
    style.opacity = 0;
    style.transition = "all 250ms";
    if (closeOnBackdropClick)
      this.backdrop.onclick = () => this.cancel();

    this.render(element, theme);

    style = this.node.style;
    style.left = '50%';
    style.top = '50%';
    style.opacity = 0;
    style.transform = "translate(-50%, -50%) scale(0, 0)";
    style.transition = "all 250ms";
  }

  show()
  {
    this.backdrop.offsetHeight;
    this.node.offsetHeight;
    this.backdrop.style.opacity = .5;
    this.node.style.opacity = 1;
    this.node.style.transform = "translate(-50%, -50%) scale(1, 1)";
  }

  dispose()
  {
    super.dispose();

    if (this.backdrop)
    {
      this.backdrop.remove();
      this.backdrop = null;
    }
  }
}

// -----------------------------------------------------------------------------
//    WindowManager
// -----------------------------------------------------------------------------

/**
 * The window manager.
 *
 * This service tracks the various windows (alerts, modals, and popovers) created using {@link :~Prompt}.
 *
 * Use the {@link :.WindowManager.instance} property to access the single instance of this class.
 */
export class WindowManager
{
  constructor()
  {
    this._alerts = [ ];
    this._escapables = [ ];

    this._root = document.createElement('div');
    document.body.appendChild(this._root);

    document.addEventListener('keydown', evt =>
    {
      if (evt.key === 'Escape' && ! evt.defaultPrevented && this._escapables.length !== 0)
        this._escapables[this._escapables.length - 1].cancel();
    });
  }

  /**
   * The window manager instance.
   *
   * @type {:.WindowManager}
   */
  static get instance()
  {
    if (alertManager === null)
      alertManager = new WindowManager();
    return alertManager;
  }

  // Internal API.
  addAlert(alert)
  {
    alert.addEventListener('close', () =>
    {
      if (this._alerts.length === 1)
        alert.node.style.transform = "translate(0, 100%)";
      alert.node.style.opacity = 0;
      setTimeout(() => alert.dispose(), 250);
    }, { once: true });

    alert.addEventListener('dispose', () =>
    {
      let index = this._alerts.indexOf(alert);
      if (index >= 0)
      {
        this._alerts.splice(index, 1);
        this._updateAlertPositions();
      }
    }, { once: true });

    this._root.appendChild(alert.node);
    this._alerts.unshift(alert);
    alert.height;                                               // Forces draw before transition
    this._updateAlertPositions();
    return alert;
  }

  // Internal API.
  addPopover(window, belowElement)
  {
    window.addEventListener('close', () =>
    {
      window.node.style.opacity = 0;
      window.arrowNode.style.opacity = 0;
      setTimeout(() => window.dispose(), 250);
    }, { once: true });

    this._root.appendChild(window.node);
    this._root.appendChild(window.arrowNode);
    this._pushEscapable(window);

    return window;
  }

  // Internal API.
  addModal(modal)
  {
    modal.addEventListener('close', () =>
    {
      modal.backdrop.style.opacity = 0;
      modal.node.style.opacity = 0;
      setTimeout(() => modal.dispose(), 500);
    }, { once: true });

    this._root.appendChild(modal.backdrop);
    this._root.appendChild(modal.node);
    this._pushEscapable(modal);
    modal.show();
    return modal;
  }

  /**
   * The topmost modal window or popover.
   *
   * This property will be `null` if there is currently no window or popover displayed.
   *
   * @type {?:~Window}
   */
  get topWindow()
  {
    return this._escapables.length ? this._escapables[this._escapables.length - 1] : null;
  }

  _pushEscapable(window)
  {
    window.addEventListener('dispose', () =>
    {
      let index = this._escapables.indexOf(window);
      if (index >= 0)
        this._escapables.splice(index, 1);
    }, { once: true });

    this._escapables.push(window);
  }

  _updateAlertPositions()
  {
    let offset = -16;
    for (let alert of this._alerts)
    {
      alert.node.style.transform = `translate(0, ${offset}px)`;
      offset -= alert.height + 16;
    }
  }
}

// -----------------------------------------------------------------------------
//    Prompt
// -----------------------------------------------------------------------------

/**
 * A utility class that provides several kinds of popup windows.
 *
 * The {@link :.usePrompt} hook provides an instance of this class associated with the calling component.
 *
 * @inner
 */
class Prompt
{
  constructor()
  {
    this.manager = WindowManager.instance;
    this.theme = null;

    this._windows = [ ];
  }

  /**
   * Presents a new window that contains an {@link :.Alert}.
   *
   * Alert windows roll up from the bottom of the viewport. An alert window can be dismissed by clicking its close
   * icon. You can also configure the window to close automatically after a specified duration.
   *
   * Note that there are shortcut methods for {@link :~Prompt#success}, {@link :~Prompt#warning}, and
   * {@link :~Prompt#error}.
   *
   * @param {string} caption - The caption to display.
   * @param {Object} [options] - Options.
   * @param {*} [options.body=null] - An optional body to render below the caption. This could be a string or a component.
   * @param {string} [options.type='info'] - The alert type: `'success'`, `'info'`, `'error'`, or `'warning'`.
   * @param {number} [options.timeout=10000] - The number of milliseconds before the alert is automatically closed, or 0
   *   to never time out.
   * @param {string} [options.width='400px'] - The width of the alert window.
   * @param {boolean} [options.detached=true] - If `true`, the alert is allowed to outlive the component that created it.
   *   if `false`, the alert is automatically closed if the calling component is unmounted.
   * @param {function} [options.onClick] - An optional handler invoked if the user clicks the alert window (outside of
   *   the close icon).
   * @returns {:~Window} The window.
   */
  alert(caption, { body=null, type='info', timeout=10000, width='400px', detached=true, onClick }={})
  {
    let alert = new AlertWindow(this.theme, caption, body, { type, timeout, width, onClick });
    this.manager.addAlert(alert);
    if (! detached)
      this._addWindow(alert);
    return alert;
  }

  /**
   * Presents a new window that contains a success {@link :.Alert}.
   *
   * Alert windows roll up from the bottom of the viewport. An alert window can be dismissed by clicking its close
   * icon. You can also configure the window to close automatically after a specified duration.
   *
   * @param {string} caption - The caption to display.
   * @param {Object} [options] - Options.
   * @param {*} [options.body=null] - An optional body to render below the caption. This could be a string or a component.
   * @param {number} [options.timeout=10000] - The number of milliseconds before the alert is automatically closed, or 0
   *   to never time out.
   * @param {string} [options.width='400px'] - The width of the alert window.
   * @param {boolean} [options.detached=true] - If `true`, the alert is allowed to outlive the component that created it.
   *   if `false`, the alert is automatically closed if the calling component is unmounted.
   * @param {function} [options.onClick] - An optional handler invoked if the user clicks the alert window (outside of
   *   the close icon).
   * @returns {:~Window} The window.
   */
  success(caption, { body=null, timeout=10000, width='400px', detached=true, onClick }={})
  {
    return this.alert(caption,
    {
      type: 'success',
      body,
      timeout,
      width,
      detached,
      onClick
    });
  }

  /**
   * Presents a new window that contains a warning {@link :.Alert}.
   *
   * Alert windows roll up from the bottom of the viewport. An alert window can be dismissed by clicking its close
   * icon. You can also configure the window to close automatically after a specified duration.
   *
   * @param {string} caption - The caption to display.
   * @param {Object} [options] - Options.
   * @param {*} [options.body=null] - An optional body to render below the caption. This could be a string or a component.
   * @param {number} [options.timeout=10000] - The number of milliseconds before the alert is automatically closed, or 0
   *   to never time out.
   * @param {string} [options.width='400px'] - The width of the alert window.
   * @param {boolean} [options.detached=true] - If `true`, the alert is allowed to outlive the component that created it.
   *   if `false`, the alert is automatically closed if the calling component is unmounted.
   * @param {function} [options.onClick] - An optional handler invoked if the user clicks the alert window (outside of
   *   the close icon).
   * @returns {:~Window} The window.
   */
  warning(caption, { body=null, timeout=10000, width='400px', detached=true, onClick }={})
  {
    return this.alert(caption,
    {
      type: 'warning',
      body,
      timeout,
      width,
      detached,
      onClick
    });
  }

  /**
   * Presents a new window that contains an error {@link :.Alert}.
   *
   * Alert windows roll up from the bottom of the viewport. An alert window can be dismissed by clicking its close
   * icon. You can also configure the window to close automatically after a specified duration.
   *
   * @param {Error | string} err - The error string or {@link Error}. If an error object is passed, its `message`
   *   becomes the alert caption.
   * @param {Object} [options] - Options.
   * @param {*} [options.body=null] - An optional body to render below the caption. This could be a string or a component.
   * @param {number} [options.timeout=0] - The number of milliseconds before the alert is automatically closed. By
   *   default, error alerts do not time out.
   * @param {string} [options.width='400px'] - The width of the alert window.
   * @param {boolean} [options.detached=true] - If `true`, the alert is allowed to outlive the component that created it.
   *   if `false`, the alert is automatically closed if the calling component is unmounted.
   * @param {function} [options.onClick] - An optional handler invoked if the user clicks the alert window (outside of
   *   the close icon).
   * @returns {:~Window} The window.
   */
  error(err, { caption="The request failed due to an error.", timeout=0, width='400px', detached=true, onClick }={})
  {
    return this.alert(caption,
    {
      type: 'error',
      body: typeof err === 'object' ? err.message : err,
      timeout,
      width,
      detached,
      onClick
    });
  }

  /**
   * Schedules an {@link :~Prompt#error} alert to appear if the specified promise rejects.
   *
   * Nothing is done if the promise resolves without error.
   *
   * @param {Promise} promise - The promise.
   * @param {Object} [options] - Options passed to {@link :~Prompt#error}.
   */
  async maybeError(promise, options)
  {
    try
    {
      await promise;
    }
    catch (err)
    {
      this.error(err, options);
    }
  }

  /**
   * Displays a popover that points to the specified DOM element.
   *
   * Popovers are useful for tooltips and menus.
   *
   * @param {string | :~Element} element - The string or component to display within the popover.
   * @param {HTMLElement} belowElement - The DOM node near which to place the popover.
   * @param {Object} [options] - Options.
   * @param {number} [options.timeout=0] - The number of milliseconds before the popover is automatically closed, or 0
   *   to never time out the popover.
   * @param {string} [options.width] - An explicit width to use for the popover. If omitted, the popover is sized based
   *   on its content.
   * @param {string} [options.flavor='light'] - The color scheme to use for the popover: `'light'` or `'dark'`.
   * @returns {:~Window} The window.
   */
  popover(element, belowElement, { timeout=0, width, flavor='light' }={})
  {
    let pop = new PopoverWindow(this.theme, element, { timeout, width, flavor });
    this.manager.addPopover(pop);
    this._addWindow(pop);

    let rc = belowElement.getBoundingClientRect();
    let xCenter = rc.left + (rc.right - rc.left) / 2 + window.scrollX;
    let x = xCenter - pop.width / 2;
    if (x < 0)
      x = 0;
    else if (x + pop.width > document.body.clientWidth)
      x = document.body.clientWidth - pop.width;
    let y = rc.bottom + 2 + window.scrollY;
    pop._show(x, y, xCenter);

    return pop;
  }

  /**
   * Displays a modal window that contains arbitrary content.
   *
   * Note that no window chrome is provided; the specified element is rendered as-is. {@link :.TitledWindow} is a handy
   * wrapper that renders a window with a title bar.
   *
   * The window is automatically closed if the calling component is unmounted from the tree.
   *
   * If the window needs to return a result, the {@link :~Prompt#ask} method is a useful alternative.
   *
   * @example
   * const prompt = usePrompt();
   *
   * const show = () => prompt.modal(
   *   <TitledWindow title="Sample Window">
   *     Hello, world
   *   </TitledWindow>
   * );
   *
   * @param {string | :~Element} element - The content to display.
   * @param {Object} [options] - Options.
   * @param {number} [options.timeout=0] - The number of milliseconds before the window is automatically closed, or 0
   *   to never time out the window.
   * @param {boolean} [options.closeOnBackdropClick=false] - If `true`, the window is closed if the user clicks
   *   outside of its bounds; if `false`, the window must be closed by other means.
   * @returns {:~Window} The window.
   */
  modal(element, { timeout=0, closeOnBackdropClick=false }={})
  {
    let modal = new ModalWindow(this.theme, element, { timeout, closeOnBackdropClick });
    this.manager.addModal(modal);
    this._addWindow(modal);
    return modal;
  }

  /**
   * Displays a modal window that asynchronously returns a result.
   *
   * The window component should call {@link :.usePromptWindow} to access the window and invoke the window's
   * {@link :~Window#commit} method when the result is ready. Alternatively, if the user decides to cancel
   * the request, the window's {@link :~Window#cancel} method should be called.
   *
   * Note that no window chrome is provided; the specified element is rendered as-is. {@link :.TitledWindow} is a handy
   * wrapper that renders a window with a title bar.
   *
   * The window is automatically closed if the calling component is unmounted from the tree.
   *
   * @example
   * const prompt = usePrompt();
   *
   * const rename = name =>
   * {
   *   const newName = await prompt.ask(<NameEditor name={name} />);
   *   if (newName !== undefined)
   *     doRename(name, newName);
   * };
   *
   * @param {string | :~Element} element - The content to display.
   * @param {Object} [options] - Options.
   * @param {number} [options.timeout=0] - The number of milliseconds before the window is automatically closed, or 0
   *   to never time out the window.
   * @param {boolean} [options.closeOnBackdropClick=false] - If `true`, the window is canceled if the user clicks
   *   outside of its bounds; if `false`, the window must be canceled by other means.
   * @returns {*} The result.
   */
  async ask(element, { timeout=0, closeOnBackdropClick=false }={})
  {
    let modal = new ModalWindow(this.theme, element, { timeout, closeOnBackdropClick });
    this.manager.addModal(modal);
    this._addWindow(modal);
    return await modal.result;
  }

  /**
   * Displays a modal confirmation window and asynchronously provides the result.
   *
   * The window is automatically closed if the calling component is unmounted from the tree.
   *
   * @param {string | :~Element} message - The message to display.
   * @param {Object} [options] - Options.
   * @param {string | :~Element} [options.acceptLabel='OK'] - The label of the affirmative button.
   * @param {string | :~Element} [options.rejectLabel='Cancel'] - The label of the negative button.
   * @param {number} [options.timeout=0] - The number of milliseconds before the window is automatically closed, or 0
   *   to never time out the window.
   * @param {boolean} [options.closeOnBackdropClick=false] - If `true`, the window is closed if the user clicks
   *   outside of its bounds; if `false`, the window must be closed by other means.
   * @returns {boolean} `true` if the user confirms the request, or `false` if not.
   */
  async confirm(message, { acceptLabel, rejectLabel, ...modalOptions}={})
  {
    return await this.ask(<Confirmation message={message} acceptLabel={acceptLabel} rejectLabel={rejectLabel} />, modalOptions);
  }

  /**
   * Displays a modal confirmation window for a destructive operation and asynchronously provides the result.
   *
   * The window is automatically closed if the calling component is unmounted from the tree.
   *
   * @param {string | :~Element} message - The message to display.
   * @param {Object} [options] - Options.
   * @param {number} [options.timeout=0] - The number of milliseconds before the window is automatically closed, or 0
   *   to never time out the window.
   * @param {boolean} [options.closeOnBackdropClick=false] - If `true`, the window is closed if the user clicks
   *   outside of its bounds; if `false`, the window must be closed by other means.
   * @returns {boolean} `true` if the user confirms the request, or `false` if not.
   */
  async confirmDelete(message, modalOptions)
  {
    return await this.ask(<DeleteConfirmation message={message} />, modalOptions);
  }

  // Internal API.
  dispose()
  {
    let windows = this._windows;
    this._windows = [ ];
    for (let window of windows)
      window.close();
  }

  _addWindow(window)
  {
    this._windows.push(window);

    window.addEventListener('dispose', () =>
    {
      let index = this._windows.indexOf(window);
      if (index >= 0)
        this._windows.splice(index, 1);
    }, { once: true });
  }
}

// -----------------------------------------------------------------------------
//    Confirmation
// -----------------------------------------------------------------------------

function Confirmation({ message, acceptLabel="OK", rejectLabel="Cancel" })
{
  const window = usePromptWindow();

  const handleAccept = () => window.commit(true);
  const handleReject = () => window.commit(false);

  return (
    <div styleName='simple-modal'>
      { message }
      <ButtonGroup justify="center">
        <Button type='danger' onClick={handleAccept}>{ acceptLabel }</Button>
        <Button type='secondary' onClick={handleReject}>{ rejectLabel }</Button>
      </ButtonGroup>
    </div>
  );
}

// -----------------------------------------------------------------------------
//    DeleteConfirmation
// -----------------------------------------------------------------------------

function DeleteConfirmation({ message })
{
  return <Confirmation message={message} acceptLabel="Delete" rejectLabel="Do Not Delete" />;
}

// -----------------------------------------------------------------------------
//    usePrompt
// -----------------------------------------------------------------------------

/**
 * Gets an instance of the {@link :~Prompt} class tied to the calling component's lifespan.
 *
 * @returns {:~Prompt} The prompt instance.
 * @hook
 */
export function usePrompt()
{
  const theme = useTheme();
  const promptRef = useRef(null);
  if (! promptRef.current)
    promptRef.current = new Prompt();
  promptRef.current.theme = theme;

  useEffect(() =>
  {
    return () => promptRef.current.dispose();
  }, []);

  return promptRef.current;
}

// -----------------------------------------------------------------------------
//    useBusy
// -----------------------------------------------------------------------------

/**
 * Returns the current status of an operation tracker.
 *
 * The returned {@link :~BusySetter} can be called at any time to indicate that an asynchronous operation has begun.
 * At that point the component will re-render, and the hook will return a flag indicating that an operation is
 * pending. Once the operation has completed, the component will again re-render with that flag cleared. This
 * allows you to use the busy flag to alter the appearance of the component (i.e. to disable buttons) when an
 * operation is in progress.
 *
 * @returns {:~BusyStatus} The busy status.
 * @hook
 */
export function useBusy()
{
  const prompt = usePrompt();
  const [ promise, setBusy ] = useState(null);

  if (promise !== null)
  {
    prompt.maybeError(promise);
    promise.finally(() => setBusy(null));
  }

  return [ promise !== null, setBusy, prompt ];
}

/**
 * The current status of an operation tracker.
 *
 * @prop {boolean} 0 - Whether an operation is currently in progress.
 * @prop {:~BusySetter} 1 - The setter.
 * @prop {:~Prompt} 2 - The associated `Prompt` instance.
 * @type {Array}
 * @typedef :~BusyStatus
 */

/**
 * Notifies the component that an operation is now in progress.
 *
 * @param {Promise} promise - The operation.
 * @callback :~BusySetter
 */

// -----------------------------------------------------------------------------
//    usePromptWindow
// -----------------------------------------------------------------------------

/**
 * Gets the window object to which the calling component renders.
 *
 * This hook is typically used to access the window's {@link :~Window#close} method in response to a
 * button being clicked within the window.
 *
 * @returns {?:~Window} The window, or `null` if the calling component is not part of a window.
 * @hook
 */
export function usePromptWindow()
{
  return useContext(WindowContext);
}
