import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOn, faToggleOff } from '@fortawesome/pro-solid-svg-icons';
import { faCircle, faCheckCircle } from '@fortawesome/pro-regular-svg-icons';

import styles from './item.css';

// -----------------------------------------------------------------------------
//    Item
// -----------------------------------------------------------------------------

export function Item({ type, cursor, selected, onClick, style, children })
{
  return (
    <div styleName={cursor ? 'cursor-item' : 'item'} onClick={onClick} style={style}>
      <FontAwesomeIcon styleName='toggle' icon={itemIcon(type, selected)} />
      { children }
    </div>
  );
}

function itemIcon(type, selected)
{
  if (type === 'single')
    return selected ? faCheckCircle : faCircle;
  return selected ? faToggleOn : faToggleOff;
}
