/** @module chassis */

import React from 'react';
import { Icon } from '@ocsoft/paper';

import styles from './section.css';

// -----------------------------------------------------------------------------
//    Sections
// -----------------------------------------------------------------------------

/**
 * A container for one or more {@link :.Section} components.
 *
 * This component simply adds appropriate margins and spacing between sections.
 *
 * @prop {:paper~Element} children - The section components.
 * @prop {...*} props - Any additional properties are forwarded to the underlying `div` element.
 *
 * @component
 */
export function Sections({ children, ...props })
{
  return (
    <div styleName="sections" {...props}>
      { children }
    </div>
  );
}

// -----------------------------------------------------------------------------
//    Section
// -----------------------------------------------------------------------------

/**
 * A discrete page section with title bar and drop shadow.
 *
 * @prop {Object | string} icon - The imported Font Awesome icon object or SVG document string to display.
 * @prop {:paper~Element} title - The section title.
 * @prop {:paper~Element} [actions] - Optional actions (typically icons) to add to the title bar.
 * @prop {:paper~Element} children - The content of the section.
 * @prop {...*} props - Any additional properties are forwarded to the underlying `section` element.
 * @component
 */
export function Section({ icon, title, actions, children, ...props })
{
  return (
    <section styleName="section" { ...props}>
      <div styleName="top">
        <div styleName="icon-box">
          <Icon icon={icon} />
        </div>
        <div styleName="title">{ title }</div>
        { actions && <div styleName="actions">{ actions }</div> }
      </div>
      <div styleName="content">{ children }</div>
    </section>
  );
}

// -----------------------------------------------------------------------------
//    EmptySection
// -----------------------------------------------------------------------------

/**
 * Replacement elements to display when a section has no content.
 *
 * This component simply configures the font size and adds appropriate padding to the child element.
 *
 * @prop {:paper~Element} children - The replacement element to render.
 * @component
 */
export function EmptySection({ children })
{
  return (
    <div styleName="empty">{ children }</div>
  );
}

// -----------------------------------------------------------------------------
//    SectionBreak
// -----------------------------------------------------------------------------

/**
 * A horizontal line that can be used to separate areas within a section.
 *
 * @prop {string} [gapAbove='16px'] - The amount of whitespace reserved above the section break.
 * @prop {string} [gapBelow='16px'] - The amount of whitespace reserved below the section break.
 * @component
 */
export function SectionBreak({ gapAbove='16px', gapBelow='16px' })
{
  return <div styleName="break" style={{ marginTop: gapAbove, marginBottom: gapBelow }} />;
}
