import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import { Timestamp } from '@ocsoft/time';
import { commit, deleteObject } from '@ocsoft/sync-object';
import { Alert, Button, ButtonGroup, Menu, MenuItem, Form, FormErrorAlert, TextField, DropList, NumberField,
         TextAreaField, SubmitButton, BusyIcon, usePrompt } from '@ocsoft/paper';
import { PageContent, Sections, Section } from '@ocsoft/chassis';
import { useSpam, createSpam } from './spam-util.js';

import { faToiletPaper as editSpamIcon, faToiletPaper as addSpamIcon } from '@fortawesome/pro-duotone-svg-icons';

import styles from './spam-page.css';

const actionMeta =
{
  values:       [ 'reject' , 'accept' ],
  labels:       [ "Reject", "Accept" ]
};

const statusMeta =
{
  values:       [ 'active' , 'disabled' ],
  labels:       [ "Active", "Disabled" ]
};

// -----------------------------------------------------------------------------
//    SpamPage
// -----------------------------------------------------------------------------

export default function SpamPage({ id=null, location })
{
  const { brandNew=false } = location.state || { };
  const [ record, status ] = useSpam(id !== null ? +id : null);

  return (
    <PageContent>
      { ! record && id !== null && status === 'loaded' && <MissingRecordAlert id={+id} /> }
      { record && <Editor record={record} brandNew={brandNew} /> }
      { id === null && <Creator /> }
    </PageContent>
  );
}

function Editor({ record, brandNew=false })
{
  const prompt = usePrompt();
  const [ data, setData ] = useState(() => editableRecord(record));
  const [ mode, setMode ] = useState(brandNew ? 'new' : 'clean');

  const validate = (data, error) =>
  {
    if (data.dest === "")
      error('dest', "One or more names must be specified");
  };

  const changed = data =>
  {
    setData(data);
    setMode('edit');
  };

  const submit = data =>
  {
    const { status, key, action, priority, comment } = data;
    return commit(record, { status, key, action, priority, comment });
  };
  const done = () => setMode('success');
  const returnToList = () => navigate('/spam');
  const handleDelete = async () =>
  {
    if (await prompt.confirm(
      <div>
        <p>You've asked to delete Spam Record <b>#{record.id}</b>.</p>
        <p>Are you sure you want to do this?</p>
      </div>, { acceptLabel: "Delete" }))
    {
      try
      {
        await deleteObject(record);
        navigate('/spam');
      }
      catch (err)
      {
        prompt.error(err);
      }
    }
  };

  useEffect(() =>
  {
    if (mode !== 'edit')
      setData(editableRecord(record));
  }, [ record, mode ]);

  return (
    <Sections>
      <Section icon={editSpamIcon} title={`Spam Record #${record.id}`}>
        <Form data={data} onChange={changed} submit={submit} onSuccess={done} validate={validate} gapAbove={16}>
          { mode === 'success' && <Alert type="success" caption="The record was successfully updated." /> }
          { mode === 'new' && <Alert type="success" caption="The record was successfully created." /> }
          <FormErrorAlert />
          <TextField name="key" label="Regular Expression" placeholder="Example: /\.spam$/" maxLength={80}
                     autoCapitalize="none" autoCorrect="off" autoComplete="off" spellCheck="false" />
          <DropList name="action" label="Action" meta={actionMeta} />
          <NumberField name="priority" label="Priority" min={0} />
          <DropList name="status" label="Status" meta={statusMeta} />
          <TextAreaField name="comment" label="Notes" rows={4} />
          <TextField name="created" label="Created" readOnly />
          <ButtonGroup justify='center'>
            <SubmitButton label="Save Changes" disabled={mode !== 'edit'} />
            <Button type="danger" onClick={handleDelete}>Delete Record</Button>
            <Button type='secondary' onClick={returnToList}>Return to List</Button>
          </ButtonGroup>
        </Form>
      </Section>
    </Sections>
  );
}

function Creator()
{
  const [ data, setData ] = useState(() => ({ key: "", action: 'reject', priority: 0, comment: "" }));

  const validate = (data, error) =>
  {
    if (! isValidKey(data.key))
      error('key', "The key must be a regular expression delimited by slashes ('/')");
  };

  const submit = data => createSpam(data);
  const done = result => navigate(`/spam/${result.id}`, { replace: true, state: { brandNew: true } });
  const returnToList = () => navigate('/spam');

  return (
    <Sections>
      <Section icon={addSpamIcon} title="New Spam Record">
        <Form data={data} onChange={data => setData(data)} submit={submit} onSuccess={done} validate={validate} gapAbove={16}>
          <FormErrorAlert />
          <TextField name="key" label="Key" placeholder="Example: ord*" maxLength={80}
                     autoCapitalize="none" autoCorrect="off" autoComplete="off" spellCheck="false" />
          <DropList name="action" label="Action" meta={actionMeta} />
          <NumberField name="priority" label="Priority" min={0} />
          <TextAreaField name="comment" label="Notes" rows={4} />
          <ButtonGroup justify='center'>
            <SubmitButton label="Create Record" />
            <Button type='secondary' onClick={returnToList}>Cancel</Button>
          </ButtonGroup>
        </Form>
      </Section>
    </Sections>
  );
}

function MissingRecordAlert({ id })
{
  return (
    <div styleName="alert">
      <Alert type="info" caption="Record Not Found">
        <p>Spam Record (<b>#{id}</b>) does not exist. Perhaps it was deleted?</p>
        <ButtonGroup>
          <Button type="secondary" onClick={() => navigate('/spam')}>Return to List</Button>
        </ButtonGroup>
      </Alert>
    </div>
  );
}

function editableRecord(record)
{
  return { ...record, created: `${Timestamp.of(record.created).longString} by ${record.creator}` };
}

function isValidKey(value)
{
  if (value.length < 2 || value[0] !== '/' || value[value.length - 1] !== '/')
    return false;
  try
  {
    new RegExp(value.substring(1, value.length - 1));
    return true;
  }
  catch
  {
    return false;
  }
}
