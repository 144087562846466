import React from 'react';
import { Fader } from './fader.js';

import styles from './overlay.css';

export function Overlay({ in: inProp=false, minWidth=200, spacing=22, enterDuration=100, exitDuration=200, children })
{
  return (
    <Fader in={inProp} enterDuration={enterDuration} exitDuration={exitDuration}>
      <div styleName='overlay' style={{ minWidth, marginTop: spacing }} tabIndex={-1}>
        { children }
      </div>
    </Fader>
  );
}
