/** @module paper */

import React, { useState, useContext, useRef, useEffect } from 'react';
import { mutate, merge, isEmptyObject } from '@ocsoft/form-util';
import { Button } from './button.js';
import { FormContext } from './form-context.js';
import { findNode } from './dom-util.js';
import { Alert } from './alert.js';

import styles from './form.css';

// -----------------------------------------------------------------------------
//    Form
// -----------------------------------------------------------------------------

/**
 * A data entry form.
 *
 * A form consists of an instance of this component containing one or more fields, accessories such as
 * {@link :.FormErrorAlert} or {@link :.SubmitButton}, or any other renderable elements. The form must be given
 * an immutable `data` object that contains the current value of each field. Any time the user manipulates a field
 * to change its value, the form invokes the supplied `onChange` handler to provide a new copy of the data object
 * with the change reflected.
 *
 * All field types are what React refers to as *controlled components*, which simply means that the components
 * do not maintain their own states; there is a single source of truth (in this case the form `data` object) that
 * contains all state. The most important consequence of that is the need to manage state above the level of the
 * form. The easiest way to achieve that is using React's `useState` hook, as illustrated in the example below.
 *
 * If an optional validation function is provided, it is invoked whenever the form data changes and given the
 * opportunity to assign errors to fields. Fields that have been visited at least once will display any associated
 * errors in a prominent location. Additionally, form submission is disabled whenever any field has an error.
 *
 * @example
 * import { useState } from 'react';
 *
 * function MyForm()
 * {
 *   const [ data, setData ] = useState({ firstName: '', lastName: '' });
 *
 *   const submit = data =>
 *   {
 *     console.log("%s %s", data.firstName, data.lastName);
 *   };
 *
 *   return (
 *     <Form data={data} onChange={setData} submit={submit}>
 *       <TextField name="firstName" label="First Name" />
 *       <TextField name="lastName" label="Last Name" />
 *       <SubmitButton />
 *     </Form>
 *   );
 * }
 *
 * @prop {Object} data - The form data.
 * @prop {:~FormChangeCallback} [onChange] - The change handler.
 * @prop {:~FormSubmitter} [submit] - A function invoked to submit the form.
 * @prop {:~FormSuccessCallback} [onSuccess] - A function invoked after the form has been successfully submitted. If the
 *   form is part of a window, you would typically close the window in response to this event.
 * @prop {function} [onReset] - A function invoked if the form is reset. The form simply clears any errors when a reset
 *   is performed. It is up to this callback function to revert the form data to its original state.
 * @prop {:~FormValidator} [validate] - An optional validation function.
 * @prop {number} [gapAbove=0] - The number of pixels of free space to reserve above the form.
 * @prop {string} [className] - A CSS class name to use for the underlying {@link HTMLElement}. The default simply
 *   establishes a flex column.
 * @prop {:~Element | :~FormRenderFunction} children - The fields and other form content, or a function invoked
 *   with various parameters to render the form content.
 * @component
 */
export function Form({ data, onChange, submit, onSuccess, onReset, validate: validator=null, gapAbove=0,
                       className=styles.form, children })
{
  const [ focus, setFocus ] = useState(null);
  const [ visited, setVisited ] = useState({ });
  const [ submitting, setSubmitting ] = useState(false);
  const [ serverError, setServerError ] = useState(null);
  const [ copied, setCopied ] = useState(null);
  const latestFocus = useRef(null);
  const formRef = useRef();
  const errors = validate(data, validator);

  useEffect(() =>
  {
    if (focus === null)
    {
      let node = findNode(formRef.current, node => node.tabIndex === 0);
      if (node !== null)
        node.focus();
    }
  }, [ ]);

  const context =
  {
    data,
    setData:      (key, value, adapter) =>
    {
      if (! submitting && key !== null && onChange)
        onChange(typeof adapter === 'object' && adapter !== null &&
                 typeof adapter.set === 'function' ? merge(data, adapter.set(key, value, data)) :
                                                     mutate(data, key, value));
    },
    merge:        delta =>
    {
      if (! submitting && onChange)
        onChange(merge(data, delta));
    },
    focus,
    setFocus:     name =>
    {
      setVisited(focus !== null ? { ...visited, [focus]: true } : visited);
      setFocus(name);
      latestFocus.current = name;
    },
    releaseFocus: name =>
    {
      // Since releaseFocus is called from a debounce timer in the Field component, we need to
      // compare against the currently focused field, not the field that was focused when the field
      // was last rendered. That is the purpose of the latestFocus ref.
      if (name === latestFocus.current)
      {
        setVisited({ ...visited, [focus]: true });
        setFocus(null);
        latestFocus.current = null;
      }
    },
    errors,
    visited,
    submitting,
    submit: async () =>
    {
      if (submit !== null && submit !== undefined && ! submitting && isEmptyObject(errors))
      {
        setSubmitting(true);
        try
        {
          let result = await submit(data);
          setServerError(null);
          setSubmitting(false);
          if (onSuccess)
            onSuccess(result);
        }
        catch (err)
        {
          setServerError(err);
          setSubmitting(false);
        }
      }
    },
    reset: () =>
    {
      if (onReset)
        onReset();
      setVisited({ });
      setServerError(null);
    },
    serverError,
    copied,
    setCopied
  }

  return (
    <FormContext.Provider value={context}>
      <div ref={formRef} className={className} style={{ marginTop: `${gapAbove}px` }}>
        { typeof children === 'function' ? children({ data, submitting, focus, submit: context.submit, reset: context.reset })
                                         : children }
      </div>
    </FormContext.Provider>
  );
}

function validate(data, validator)
{
  let errors = { };
  if (typeof validator === 'function')
  {
    try
    {
      validator(data, (key, message) =>
      {
        errors[key] = message;
      });
    }
    catch (err)
    {
      console.error("Warning: Form validator threw error: %s\n%s", err.message, err.stack);
    }
  }
  return errors;
}

// -----------------------------------------------------------------------------
//    SubmitButton
// -----------------------------------------------------------------------------

/**
 * A button that submits its form when pressed.
 *
 * This component may only appear within a {@link :.Form}.
 *
 * @prop {:.Button~Type} [type='primary'] - The button type.
 * @prop {string} [label='Submit'] - The button's label. This property is overridden if the button has any
 *   child elements.
 * @prop {...*} [props] - Any additional properties are applied to the underlying {@link :.Button}.
 * @component
 */
export function SubmitButton({ type='primary', label='Submit', disabled=false, children, ...props })
{
  const context = useContext(FormContext);

  disabled = disabled || context.submitting || ! isEmptyObject(context.errors);

  return (
    <Button {...props} type={type} disabled={disabled} onClick={() => context.submit()}>
      { children ?? label }
    </Button>
  );
}

// -----------------------------------------------------------------------------
//    ResetButton
// -----------------------------------------------------------------------------

/**
 * A button that resets its form when pressed.
 *
 * This component may only appear within a {@link :.Form}.
 *
 * @prop {:.Button~Type} [type='secondary'] - The button type.
 * @prop {string} [label='Reset'] - The button's label. This property is overridden if the button has any
 *   child elements.
 * @prop {:~Component} [component=null] - An optional alternative component to use to display the button. If omitted,
 *   a {@link :.Button} is used.
 * @prop {...*} [props] - Any additional properties are applied to the underlying {@link :.Button}.
 * @component
 */
export function ResetButton({ type='secondary', label='Reset', delay=false, children, component=null, ...props })
{
  const context = useContext(FormContext);
  const disabled = context.submitting;
  const onClick = evt => context.reset();

  return component !== null ? component({ onClick, disabled }) :
    <Button {...props} type={type} disabled={disabled} onClick={onClick}>
      { children ?? label }
    </Button>;
}

// -----------------------------------------------------------------------------
//    FormErrorAlert
// -----------------------------------------------------------------------------

/**
 * An {@link :.Alert} that displays any server errors that occur when submitting a form.
 *
 * The alert is hidden whenever there is no error.
 *
 * This component may only appear within a {@link :.Form}.
 *
 * @component
 */
export function FormErrorAlert()
{
  const context = useContext(FormContext);
  if (context.serverError === null)
    return null;

  return (
    <Alert type='error' caption="An error occurred:" body={context.serverError.message} />
  );
}

// -----------------------------------------------------------------------------
//    FormText
// -----------------------------------------------------------------------------

/**
 * Content to display above a form.
 *
 * The content is set off from the form itself by a horizontal separator line.
 *
 * @prop {:~Element} children - The content.
 *
 * @component
 */
export function FormText({children })
{
  return (
    <div styleName="form-text">{ children }</div>
  );
}

// -----------------------------------------------------------------------------
//    useFormContext (Hook)
// -----------------------------------------------------------------------------

/**
 * Gets information about the {@link :.Form} that encloses the calling component.
 *
 * This hook is intended to be used in the implementation of custom fields.
 *
 * @returns {:~FormContext} The form context.
 * @hook
 */
export function useFormContext()
{
  return useContext(FormContext);
}

// -----------------------------------------------------------------------------
//    Documentation Typedefs
// -----------------------------------------------------------------------------

/**
 * A callback function invoked when the form's data changes.
 *
 * @param {Object} data - The new form data.
 * @callback :~FormChangeCallback
 */

/**
 * A form validator.
 *
 * The validator should examine the form data and call the provided `error` function for each
 * field with an invalid value.
 *
 * @param {Object} data - The current form data.
 * @param {:~FormErrorFunction} error - The error function.
 * @callback :~FormValidator
 */

/**
 * A callback function invoked when the form's data changes.
 *
 * @param {*} result - The value returned by the form submitter.
 * @callback :~FormSuccessCallback
 */

/**
 * Declares an error in one of a form's fields.
 *
 * @param {:~FieldName} name - The field name.
 * @param {string} message - The error message.
 * @callback :~FormErrorFunction
 */

/**
 * A callback function invoked to submit a form.
 *
 * The function may be synchronous or asynchronous. If an error is thrown, the error message will be
 * displayed by the form's {@link :.FormErrorAlert} component.
 *
 * @param {Object} data - The form data.
 * @returns {*} An optional result value.
 * @callback :~FormSubmitter
 * @async
 */

/**
 * Information provided to a form render function.
 *
 * @param {Object} state - The current form state.
 * @param {Object} state.data - Current form data.
 * @param {boolean} state.submitting - Whether the form is currently being submitted.
 * @param {?:~FieldName} state.focus - The name of the field that currently has focus, or `null` if no field has focus.
 * @param {function} state.submit - A function that can be invoked to submit the form.
 * @param {function} state.reset - A function that can be invoked to reset the form.
 *
 * @returns {:~Element} The rendered fields and other form content.
 * @callback :~FormRenderFunction
 */
