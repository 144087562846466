/** @module chassis */

import React, { useRef, useState, useEffect } from 'react';
import session from '@ocsoft/session';
import { useEventTarget } from '@ocsoft/paper';

// -----------------------------------------------------------------------------
//    useAuthenticator
// -----------------------------------------------------------------------------

/**
 * Provides an authenticator for a {@link :session.Session}.
 *
 * This is a low-level building block that is used by the {@link :.PageContent} component. The only reason
 * to use this hook directly would be if you were planning not to use `PageContent`.
 *
 * @returns {:~AuthenticatorState} The current authenticator state.
 * @hook
 */
export function useAuthenticator(enable=true)
{
  const resolveRef = useRef();

  const authenticate = (username, password) =>
  {
    let resolve = resolveRef.current;
    if (resolve)
    {
      resolveRef.current = null;
      resolve({ username, password });
    }
  };

  const [ state, setState ] = useState({ status: session.status, code: '', message: '', authenticate });

  useEffect(() =>
  {
    if (enable)
    {
      const prevAuthenticator = session.authenticator;
      session.authenticator = async (code, message) =>
      {
        setState({ status: session.status, code, message, authenticate });
        let promise = new Promise(resolve => { resolveRef.current = resolve; });
        return await promise;
      };
      return () => { session.authenticator = prevAuthenticator; };
    }
  }, [ enable ]);

  useEventTarget(session, 'status', evt =>
  {
    const status = session.status;
    switch (session.status)
    {
      case 'login':
        setState({ status, code: '', message: "Authenticating...", authenticate });
        break;

      case 'idle':
        setState({ status, code: '', message:'', authenticate });
        break;
    }
  });

  return state;
}

/**
 * The authenticator state.
 *
 * @prop {string} status - The authenticator {@link :session.Session#status}.
 * @prop {string} code - The server error code.
 * @prop {string} message - The server error message.
 * @prop {:~AuthenticatorCallback} authenticate - The authentication callback.
 * @typedef {Object} :~AuthenticatorState
 */

/**
 * The callback function to be invoked when the user has entered a username and password.
 *
 * @param {string} username - The username.
 * @param {string} password - The password.
 * @callback :~AuthenticatorCallback
 */

// -----------------------------------------------------------------------------
//    useSession
// -----------------------------------------------------------------------------

/**
 * Gets the current {@link :session.Session} state.
 *
 * The calling component will re-render whenever the sesion state changes.
 *
 * @returns {:~SessionState} The current session state.
 * @hook
 */
export function useSession()
{
  const [ state, setState ] = useState({ status: session.status, username: session.username, flags: session.flags });

  const update = () =>
  {
    setState({ status: session.status, username: session.username, flags: session.flags });
  };

  useEventTarget(session, 'status', update);
  useEventTarget(session, 'update', update);

  return state;
}

/**
 * The session state.
 *
 * @prop {string} status - The {@link :session.Session#status authentication} status.
 * @prop {string} username - The username, or `undefined` if no user is logged in.
 * @prop {Object} flags - The session {@link :session.Session#flags}.
 * @typedef {Object} :~SessionState
 */
