/** @module chassis */

import React from 'react';
import { Icon } from '@ocsoft/paper';

import styles from './log-header.css';

// -----------------------------------------------------------------------------
//    LogHeader
// -----------------------------------------------------------------------------

/**
 * A header and horizontal separator, typically used for pages that display logs.
 *
 * @prop {Object | string} icon - The imported Font Awesome icon object or SVG document string to display.
 * @prop {:paper~Element} title - The title to render.
 * @prop {:paper~Element} [children] - Optional additional content to include in the header.
 * @component
 */
export function LogHeader({ icon, title, children })
{
  return (
    <div styleName="root">
      <div styleName="icon"><Icon icon={icon} /></div>
      <div styleName="title">{ title }</div>
      { children }
    </div>
  );
}
