/** @module chassis */

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSpinnerThird as spinnerIcon } from '@fortawesome/pro-duotone-svg-icons';

import styles from './loading.css';

// -----------------------------------------------------------------------------
//    Loading
// -----------------------------------------------------------------------------

/**
 * A temporary content placeholder.
 *
 * This component renders an animated spinner with a caption. It is typically shown while waiting for network data
 * to be retrieved.
 *
 * @prop {string} [text="Loading..."] - The caption to render.
 * @prop {string} [gapAbove="16px"] - Empty space to reserve above the component.
 * @prop {string} [gapBelow="16px"] - Empty space to reserve below the component.
 * @component
 */
export function Loading({ text = "Loading...", gapAbove="16px", gapBelow="16px" })
{
  return (
    <div styleName="root" style={{ marginTop: gapAbove, marginBottom: gapBelow }}>
      <FontAwesomeIcon icon={spinnerIcon} spin styleName="icon" />
      <div styleName="text">{ text }</div>
    </div>
  );
}
