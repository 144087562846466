import React, { useState } from 'react';
import { navigate } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Timestamp } from '@ocsoft/time';
import { urlOf } from '@ocsoft/sync-object';
import { useSorter } from '@ocsoft/sync-react';
import { useSearchText, IconButton, useLocalStorage } from '@ocsoft/paper';
import { PageHeader, PageContent, LogHeader, LogTimestamp, EmptySection, Loading } from '@ocsoft/chassis';
import { useSearchableAliasList } from './alias-util.js';

import { faUserTag as pageIcon } from '@fortawesome/pro-duotone-svg-icons';
import { faChevronUp as sortUpIcon, faChevronDown as sortDownIcon,
         faPlusCircle as addIcon } from '@fortawesome/pro-solid-svg-icons';

import styles from './alias-list-page.css';

// -----------------------------------------------------------------------------
//    AliasListPage
// -----------------------------------------------------------------------------

export default function AliasListPage()
{
  const searchText = useSearchText();
  const [ aliases, status ] = useSearchableAliasList();
  const [ sortMode, setSortMode ] = useLocalStorage('alias-sort-mode', "-+created");
  const sorter = useSorter(sortMode);

  const clicked = alias =>
  {
    navigate(`/aliases/${alias.key}`);
  };

  const newAlias = () => navigate(`/newalias`);

  return (
    <PageContent>
      <LogHeader icon={pageIcon} title={searchText === '' ? "All Aliases" : `Aliases Matching "${searchText}"`}>
        <IconButton icon={addIcon} styleName="add-icon" title="Add New Alias" onClick={newAlias} />
      </LogHeader>
      <ColumnHeaders sortMode={sortMode} onResort={setSortMode} />
      { status === 'loaded' && aliases.length === 0 && <EmptySection>None found.</EmptySection> }
      { status === 'loading' && <Loading /> } 
      {
        aliases.sort(sorter).map(alias =>
          <Alias key={urlOf(alias)} alias={alias} onClick={() => clicked(alias)} />)
      }
    </PageContent>
  );
}

function Alias({ alias, onClick })
{
  const className = styles[alias.status === 'active' ? 'field' : 'disabled'];
  return (
    <div styleName="alias" onClick={onClick}>
      <div className={className}>{ alias.key }</div>
      <div className={className}>{ alias.dest }</div>
      <div className={className}> { alias.site }</div>
      <div styleName="comment"> { alias.comment }</div>
      <div styleName="ts">{ Timestamp.of(alias.created).localDate.conciseString }</div>
    </div>
  );
}

function ColumnHeaders({ sortMode, onResort })
{
  return (
    <div styleName="column-headers">
      <ColumnHeader label="Key" name="key" sortMode={sortMode} onResort={onResort} />
      <ColumnHeader label="Destination" name="dest" sortMode={sortMode} onResort={onResort} />
      <ColumnHeader label="Site" name="site" sortMode={sortMode} onResort={onResort} />
      <ColumnHeader label="Notes" name="comment" sortMode={sortMode} onResort={onResort} />
      <ColumnHeader label="Created" name="+created" sortMode={sortMode} rightAlign={true} onResort={onResort} />
    </div>
  );
}

function ColumnHeader({ label, name, sortMode, rightAlign=false, onResort })
{
  const descending = sortMode[0] === '-';
  const sortName = descending ? sortMode.substring(1) : sortMode;
  const resort = () => onResort(sortName !== name ? name : `${descending ? '' : '-'}${name}`);

  return (
    <div styleName={rightAlign ? 'column-right' : 'column'} onClick={resort}>
      { label }
      { sortName === name && <FontAwesomeIcon icon={descending ? sortDownIcon : sortUpIcon} styleName="sort-icon" /> }
   </div>
  );
}
