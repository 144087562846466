/** @module paper */

import React from 'react';

export const FormContext = React.createContext(
{
  data: { },
  setData: (key, value, adapter) => { },
  merge: delta => { },
  focus: null,
  setFocus: name => { },
  releaseFocus: name => { },
  errors: { },
  visited: { },
  submitting: false,
  submit: () => { },
  reset: () => { },
  serverError: null,
  copied: null,
  setCopied: name => { }
});

// ----------------------------------------------------------------------------
//      Documentation 
// ----------------------------------------------------------------------------

/**
 * Information about a form made available to its fields via {@link :.useFormContext}.
 *
 * @interface :~FormContext
 */

/**
 * The form's current data.
 *
 * @type {Object}
 * @readonly
 * @member
 * @name :~FormContext#data
 */

/**
 * Notifies the form that the data has changed for a field.
 *
 * @param {:~FieldName} key - The field name.
 * @param {*} value - The new value for the field.
 * @param {:form-util~Adapter} [adapter] - An optional value adapter.
 * @method
 * @name :~FormContext#setData
 */

/**
 * Notifies the form that the data has changed for one or more fields.
 *
 * @param {Object} delta - The delta.
 * @method
 * @name :~FormContext#merge
 */

/**
 * Whether the form is currently being submitted.
 *
 * Fields should generally disable interactivity while submission is in progress.
 *
 * @type {boolean}
 * @readonly
 * @member
 * @name :~FormContext#submitting
 */

/**
 * The name of the field that currently has focus.
 *
 * This property will be `null` if no field currently has focus.
 *
 * @type {?string}
 * @readonly
 * @member
 * @name :~FormContext#focus
 */
