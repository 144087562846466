/** @module chassis */

import React from 'react';
import { urlOf } from '@ocsoft/sync-object';
import { useSyncList, useSorter } from '@ocsoft/sync-react';
import { Fault } from  './fault.js';

import styles from './fault-panel.css';

// -----------------------------------------------------------------------------
//    FaultPanel
// -----------------------------------------------------------------------------

/**
 * An alert panel containing a list of current server faults.
 *
 * If there are no faults, this component does not render anything.
 *
 * @component
 */
export function FaultPanel()
{
  const [ faults ] = useSyncList(`/api/faults`);
  const sorter = useSorter('-+created');

  if (faults.length === 0)
    return null;

  return (
    <div styleName="root">
    {
      faults.sort(sorter).map(fault =>
        <Fault key={urlOf(fault)} fault={fault} />)
    }
    </div>
  );
}
