export default
{
  // ----- Styles with leading dashes are available in CSS variables AND in JavaScript via the theme API. -----

  "--menu-item-border-radius":      "16px",
  "--menu-item-padding":            "5px 16px",
  "--menu-item-font":               "14px Helvetica Neue, Helvetica, Sans-Serif",
  "--menu-item-color":              "white",
  "--menu-item-bg":                 "transparent",
  "--menu-item-border":             "2px solid #bbb",
  "--menu-item-hover-box-shadow":   "0 0 4px 2px #ddd",
 
  "--menu-input-border-radius":     "16px",
  "--menu-input-padding":           "3px 8px",
  "--menu-input-font":              "14px Helvetica Neue, Helvetica, Sans-Serif",
  "--menu-input-color":             "white",
  "--menu-input-bg":                "transparent",
  "--menu-input-border":            "1px solid #bbb",
  "--menu-input-focus-box-shadow":  "0 0 4px 2px #bfb",

  // ----- Styles without leading dashes are ONLY available in JavaScript via the theme API. -----

  "popover-arrow-size":             "8px",
  "popover-border-radius":          "8px",
  "popover-font":                   "14px Helvetica Neue, Helvetica, Sans-Serif",
  "popover-padding":                "10px",
  "popover-bg":                     "linear-gradient(to bottom, #888, #222)",
  "popover-color":                  "#eee",
  "popover-box-shadow":             "4px 4px 10px #111",
  "popover-arrow-color":            "#888",
};
