/** @module paper */

import React, { useState } from 'react';
import { useEventTarget } from './hooks.js';

// -----------------------------------------------------------------------------
//    StorageCache
// -----------------------------------------------------------------------------

class StorageCache extends EventTarget
{
  constructor(storage)
  {
    super();

    this.storage = storage;
    this._cache = new Map();
  }

  get(key, defaultValue=null)
  {
    let value = this._cache.get(key);
    if (value === undefined)
    {
      value = getPersistentValue(key, defaultValue, this.storage);
      this._cache.set(key, value);
    }
    return value;
  }

  set(key, value)
  {
    if (value !== this._cache.get(key))
    {
      this._cache.set(key, value);
      setPersistentValue(key, value, this.storage);
      this.dispatchEvent(new CustomEvent('change', { detail: { key, value }}));
    }
  }
}

const localStorageCache = new StorageCache(window.localStorage);

// -----------------------------------------------------------------------------
//    getPersistentValue
// -----------------------------------------------------------------------------

export function getPersistentValue(key, defaultValue=null, storage=window.localStorage)
{
  if (! key)
    return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
  try
  {
    let value = storage.getItem(key);
    if (value === null)
      return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
    return JSON.parse(value);
  }
  catch (err)
  {
    return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
  }
}

// -----------------------------------------------------------------------------
//    setPersistentValue
// -----------------------------------------------------------------------------

export function setPersistentValue(key, value, storage=window.localStorage)
{
  if (key)
  {
    try
    {
      storage.setItem(key, JSON.stringify(value));
      return true;
    }
    catch (err)
    {
      return false;
    }
  }
}

// -----------------------------------------------------------------------------
//    useLocalStorage
// -----------------------------------------------------------------------------

/**
 * Gets the current value of (and a setter function for) a key in {@link Storage local storage}.
 *
 * The calling component will re-render whenever the value of the specified key is updated.
 *
 * Values are cached, so only the first lookup of any particular key incurs the overhead of interacting with
 * the web storage API.
 *
 * @param {string} name - The storage key.
 * @param {* | function} [defaultValue=null] - The value to return if the key does not exist, an error occurs retrieving
 *   the value, or the value fails to parse as JSON. If a function is passed, the function is called to get the default value.
 * @returns {:~StorageValue} The storage item.
 * @hook
 */
export function useLocalStorage(name, defaultValue=null)
{
  const [ state, setState ] = useState(() => localStorageCache.get(name, defaultValue));
  useEventTarget(localStorageCache, 'change', evt =>
  {
    if (evt.detail.key === name)
      setState(evt.detail.value);
  });

  const saveState = value =>
  {
    if (typeof value === 'function')
      value = value(state);
    localStorageCache.set(name, value);
  };

  return [ state, saveState ];
}

/**
 * The JSON value of a {@link Storage} key, along with a function to change that key's value.
 *
 * @prop {*} 0 - The storage key's value.
 * @prop {:~StorageSetter} 1 - A function that can be invoked to change the storage key's value.
 * @typedef {Array} :~StorageValue
 */

/**
 * A callback that can be used to change a {@link Storage} key's value.
 *
 * @param {* | function } value - The new value, or a function that can be called to return the value.
 * @callback :~StorageSetter
 */
