/** @module paper */

import React, { useRef, useState, useEffect, useContext } from 'react';
import { pick } from '@ocsoft/form-util';
import { FormContext } from './form-context.js';
import { Field } from './field.js';
import { AutoComplete} from './autocomplete.js';
import { IconButton } from './icon-button.js';

import { faCopy as copyIcon, faClipboardCheck as copiedIcon,
         faEye as showIcon, faEyeSlash as hideIcon } from '@fortawesome/pro-solid-svg-icons';

import styles from './text-field.css';

// -----------------------------------------------------------------------------
//    TextField
// -----------------------------------------------------------------------------

/**
 * A single line text entry field.
 *
 * This component may only be used inside a {@link :.Form}.
 *
 * @prop {:~FieldName} name - The field name. The corresponding form data property should be a string.
 * @prop {string} label - The field label.
 * @prop {boolean} [hideLabel=false] - Whether to hide the field label.
 * @prop {string} [type='text'] - The input field type (e.g. `'password'` or `'url'`).
 * @prop {boolean} [copyShortcut=false] - Whether to include an icon that allows the user to copy the content of the
 *   field to the clipboard.
 * @prop {:~AutocompleteFetcher} [autocomplete] - An optional autocomplete callback.
 * @prop {:~AutocompleteRenderFunction} [autoRenderItem] - An optional callback used to render each autocomplete option.
 *   The default simply stringifies the option.
 * @prop {:~AutocompleteApplicator} [autoApply] - An optional function invoked to apply a selected autocomplete option
 *   to the form's data.
 * @prop {:~Element} [customActions] - An optional icon or other element(s) to render on the righthand side of the input field.
 * @prop {...*} [props] - Any additional properties are forwarded to the underlying input element.
 * @component
 */
export function TextField({ name='', label="", hideLabel=false, type='text', copyShortcut=false, customActions,
                            autocomplete=null, autoRenderItem, autoItemKey, autoApply, ...props })
{
  const inputRef = useRef();
  const { data, setData, merge, submitting, focus, copied, setCopied } = useContext(FormContext);
  const value = pick(data, name, "");

  const changed = evt => setData(name, evt.target.value);
  const onText = text =>
  {
    if (autoApply)
      merge(autoApply(text));
    else
      setData(name, text);
  };

  if (autocomplete === null)
  {
    const copy = evt =>
    {
      const node = inputRef.current;
      node.select();
      if (document.execCommand('copy'))
      {
        evt.currentTarget.focus();
        node.setSelectionRange(node.value.length, node.value.length);
        setCopied(name);
      }
    };

    return (
      <Field name={name} label={label} hideLabel={hideLabel} grow>
        <div styleName="input-row">
          <input ref={inputRef} type={type} styleName='text' value={value} disabled={submitting} onChange={changed} { ...props } />
          { customActions }
          { copyShortcut &&
            <IconButton icon={copied === name ? copiedIcon : copyIcon} disabled={value === ''}
                        styleName={copied === name ? 'copied-icon' : 'copy-icon'} title="Copy" onClick={copy} /> }
        </div>
      </Field>
    );
  }

  return (
    <Field name={name} label={label} hideLabel={hideLabel} grow>
      <AutoComplete text={value} onText={onText} visible={focus === name && ! submitting} fetcher={autocomplete}
                    renderItem={autoRenderItem} itemKey={autoItemKey}>
      {
        ({ onKeyDown}) => <input type={type} styleName='text' value={value} disabled={submitting}
                                 onChange={changed} onKeyDown={onKeyDown} { ...props } />
      }
      </AutoComplete>
    </Field>
  );
}

// -----------------------------------------------------------------------------
//    TextAreaField
// -----------------------------------------------------------------------------

/**
 * A multi-line text entry field.
 *
 * This component may only be used inside a {@link :.Form}.
 *
 * @prop {:~FieldName} name - The field name. The corresponding form data property should be a string.
 * @prop {string} label - The field label.
 * @prop {boolean} [hideLabel=false] - Whether to hide the field label.
 * @prop {...*} [props] - Any additional properties are forwarded to the underlying `textarea` element.
 * @component
 */
export function TextAreaField({ name='', label="", hideLabel=false, ...props })
{
  const { data, setData, submitting } = useContext(FormContext);
  const value = pick(data, name, "");

  const changed = evt => setData(name, evt.target.value);

  return (
    <Field name={name} label={label} hideLabel={hideLabel} grow submitOnEnter={false}>
      <textarea styleName='text' value={value} disabled={submitting} onChange={changed} { ...props } />
    </Field>
  );
}

// -----------------------------------------------------------------------------
//    NumberField
// -----------------------------------------------------------------------------

/**
 * A numeric text entry field.
 *
 * This component may only be used inside a {@link :.Form}.
 *
 * @prop {:~FieldName} name - The field name. The corresponding form data property should be a {@link number}.
 * @prop {string} label - The field label.
 * @prop {boolean} [hideLabel=false] - Whether to hide the field label.
 * @prop {string} [type='number'] - The `input` element type.
 * @prop {*} [emptyValue=null] - The value represented by an empty field.
 * @prop {...*} [props] - Any additional properties are forwarded to the underlying `input` element.
 * @component
 */
export function NumberField({ name='', label="", hideLabel=false, type='number', emptyValue=null, ...props })
{
  const { data, setData, submitting } = useContext(FormContext);
  const [ text, setText ] = useState("");
  const value = pick(data, name, "");

  useEffect(() =>
  {
    setText(value === emptyValue ? "" : String(value));
  }, [ value ]);

  const changed = evt =>
  {
    let str = evt.target.value.trim();
    setText(str);
    setData(name, str !== "" ? +str : emptyValue);
  };

  return (
    <Field name={name} label={label} hideLabel={hideLabel} grow submitOnEnter={false}>
      <input type={type} styleName='text' value={text} disabled={submitting} onChange={changed} { ...props } />
    </Field>
  );
}

// -----------------------------------------------------------------------------
//    PasswordField
// -----------------------------------------------------------------------------

/**
 * A password entry field.
 *
 * This component may only be used inside a {@link :.Form}.
 *
 * @prop {:~FieldName} name - The field name. The corresponding form data property should be a string.
 * @prop {string} label - The field label.
 * @prop {boolean} [hideLabel=false] - Whether to hide the field label.
 * @prop {boolean} [allowShow=false] - Whether to include an icon that can be clicked to unmask the password.
 * @prop {...*} [props] - Any additional properties are forwarded to the underlying `input` element.
 * @component
 */
export function PasswordField({ name='', label="", hideLabel=false, allowShow=false, ...props })
{
  const [ showing, setShowing ] = useState(false);
  const { data, setData, submitting } = useContext(FormContext);
  const value = pick(data, name, "");

  const toggleShowing = () => setShowing(! showing);
  const changed = evt => setData(name, evt.target.value);

  return (
    <Field name={name} label={label} hideLabel={hideLabel} grow>
      <div styleName="input-row">
        <input type={showing ? 'text' : 'password'} styleName='text' value={value}
               disabled={submitting} onChange={changed} { ...props } />
        { allowShow && <IconButton icon={showing ? showIcon : hideIcon} styleName="show-icon" onClick={toggleShowing} /> }
      </div>
    </Field>
  );
}

