/** @module paper */

import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';

// -----------------------------------------------------------------------------
//    SearchContext
// -----------------------------------------------------------------------------

const SearchContext = React.createContext(
{
  text:           '',
  normalized:     '',
  updateText:     text => { },
  subscribed:     false,
  subscribe:      () => { },
  unsubscribe:    () => { }
});

// -----------------------------------------------------------------------------
//    SearchRoot Component
// -----------------------------------------------------------------------------

/**
 * The root of a search tree.
 *
 * A search tree ties together all components that use the text from a given {@link :.SearchInput} to filter
 * what they render.
 *
 * @example
 * <SearchRoot>
 *   <SearchInput type="search" />
 *   ...
 * </SearchRoot>
 *
 * @prop {:~Element} children - The search tree.
 * @component
 */
export function SearchRoot({ children })
{
  const [ text, setText ] = useState('');
  const [ normalized, setNormalized ] = useState('');
  const [ subscribed, setSubscribed ] = useState(false);
  const subscriptions = useRef(0);

  const updateText = text =>
  {
    setText(text);
    setNormalized(text.trim().toLowerCase());
  };

  const subscribe = () =>
  {
    ++ subscriptions.current;
    if (subscriptions.current === 1)
      setSubscribed(true);
  };

  const unsubscribe = () =>
  {
    -- subscriptions.current;
    if (subscriptions.current === 0)
      setSubscribed(false);
  };

  return (
    <SearchContext.Provider value={{ text, normalized, updateText, subscribed, subscribe, unsubscribe }}>
      { children }
    </SearchContext.Provider>
  );
}

// -----------------------------------------------------------------------------
//    useSearch Hook
// -----------------------------------------------------------------------------

/**
 * Obtains a match function that can be used to test strings against the search text associated with the nearest
 * ancestor {@link :.SearchRoot}.
 *
 * The match function is memoized based on the search text; the same function instance is returned unless the search text
 * has changed since the previous render.
 *
 * @example
 * function MyList()
 * {
 *   const items = getItemsFromSomewhere();
 *   const found = useSearch();
 *   return (
 *     <div>
 *       { items.filter(item => found(item.name)).map(item => <Item key={item.id} data={item} />) }
 *      </div>
 *   );
 * }
 *
 * @param {Object} [options] - Options.
 * @param {boolean} [options.enabled=true] - Whether to participate in the search. If this property is `false`, the
 *   search input will be disabled unless there is another component that has invoked this hook.
 * @param {boolean} [options.nullIfEmpty=false] - Whether to return `null` instead of a match function if the search text is
 *   empty.
 * @returns {:~MatchFunction} The matcher, or `null` if the search text is empty and the `nullIfEmpty` option was set to `true`.
 * @hook
 */
export function useSearch({ enabled=true, nullIfEmpty=false }={})
{
  const { normalized, subscribe, unsubscribe } = useContext(SearchContext);

  useEffect(() =>
  {
    if (enabled)
    {
      subscribe();
      return () => unsubscribe();
    }
  }, [ enabled ]);

  return useMemo(() =>
  {
    if (! enabled || normalized.length === 0)
      return nullIfEmpty ? null : () => true;
    return value => String(value).toLowerCase().indexOf(normalized) >= 0;
  }, [ enabled, normalized, nullIfEmpty ]);
}

/**
 * Indicates whether a given substring is found within the search text associated with the nearest ancestor {@link :.SearchRoot}.
 *
 * The match is case-insensitive.
 *
 * @param {*} value - The value to match.
 * @returns {boolean} `true` if the value is found in the current search text; `false` if not.
 * @callback :~MatchFunction
 */

// -----------------------------------------------------------------------------
//    useSearchText Hook
// -----------------------------------------------------------------------------

/**
 * Gets the current search text associated with the nearest ancestor {@link :.SearchRoot}.
 *
 * Unlike {@link :.useSearch}, this hook does *not* express interest in the search text; if no component has
 * invoked `useSearch`, the search input will remain disabled.
 *
 * @returns {string} The search text.
 * @hook
 */
export function useSearchText()
{
  const { normalized, text } = useContext(SearchContext);

  return normalized.length !== 0 ? text : "";
}

// -----------------------------------------------------------------------------
//    useSearchInjector Hook
// -----------------------------------------------------------------------------

/**
 * Sets the current search text associated with the nearest ancestor {@link :.SearchRoot}.
 *
 * @param {string} text - The search text.
 * @param {boolean} [cleanup=true] - Whether to clear the search text when the calling component is unmounted.
 * @hook
 */
export function useSearchInjector(text, cleanup=true)
{
  const { updateText } = useContext(SearchContext);

  useEffect(() =>
  {
    if (text !== null)
    {
      updateText(text);
      if (cleanup)
        return () => updateText("");
    }
  }, [ text ]);
}

// -----------------------------------------------------------------------------
//    SearchInput
// -----------------------------------------------------------------------------

/**
 * The search `<input>` element associated with the nearest ancestor {@link :.SearchRoot}.
 *
 * Note that Paper does not style this element at all; that is left up to the caller.
 *
 * @prop {...*} props - All properties are forwarded to the underlying input element.
 * @component
 */
export function SearchInput(props)
{
  const { text, updateText, subscribed } = useContext(SearchContext);

  return (
    <input value={text} disabled={! subscribed} onChange={evt => updateText(evt.target.value)} { ...props } />
  );
}
