import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import { Timestamp } from '@ocsoft/time';
import { commit, deleteObject } from '@ocsoft/sync-object';
import session from '@ocsoft/session';
import { Username } from '@ocsoft/auth-util';
import { Alert, Button, ButtonGroup, Menu, MenuItem, Form, FormErrorAlert, TextField, DropList, PasswordField,
         TextAreaField, SubmitButton, BusyIcon, usePrompt } from '@ocsoft/paper';
import { PageContent, Sections, Section } from '@ocsoft/chassis';
import { useAlias, createAlias, useAliasList } from './alias-util.js';

import { faUserEdit as editAliasIcon, faUserPlus as addAliasIcon } from '@fortawesome/pro-duotone-svg-icons';

import styles from './alias-page.css';

const statusMeta =
{
  values:       [ 'active' , 'disabled' ],
  labels:       [ "Active", "Disabled" ]
};

// -----------------------------------------------------------------------------
//    AliasPage
// -----------------------------------------------------------------------------

export default function AliasPage({ aliasKey=null, location })
{
  const { brandNew=false } = location.state || { };
  const [ alias, status ] = useAlias(aliasKey);

  return (
    <PageContent>
      { ! alias && aliasKey !== null && status === 'loaded' && <MissingAliasAlert aliasKey={aliasKey} /> }
      { alias && <Editor alias={alias} brandNew={brandNew} /> }
      { aliasKey === null && <Creator /> }
    </PageContent>
  );
}

function Editor({ alias, brandNew=false })
{
  const prompt = usePrompt();
  const [ data, setData ] = useState(() => editableAlias(alias));
  const [ mode, setMode ] = useState(brandNew ? 'new' : 'clean');

  const validate = (data, error) =>
  {
    if (data.dest === "")
      error('dest', "One or more names must be specified");
  };

  const changed = data =>
  {
    setData(data);
    setMode('edit');
  };

  const submit = data =>
  {
    const { status, dest, site, siteUser, sitePassword, comment } = data;
    return commit(alias, { status, dest, site, siteUser, sitePassword, comment });
  };
  const done = () => setMode('success');
  const returnToList = () => navigate('/aliases');
  const handleDelete = async () =>
  {
    if (await prompt.confirm(
      <div>
        <p>You've asked to delete alias <b>{alias.key}</b>.</p>
        <p>Are you sure you want to do this?</p>
      </div>, { acceptLabel: "Delete" }))
    {
      try
      {
        await deleteObject(alias);
        navigate('/aliases');
      }
      catch (err)
      {
        prompt.error(err);
      }
    }
  };

  useEffect(() =>
  {
    if (mode !== 'edit')
      setData(editableAlias(alias));
  }, [ alias, mode ]);

  return (
    <Sections>
      <Section icon={editAliasIcon} title={`Alias "${alias.key}"`}>
        <Form data={data} onChange={changed} submit={submit} onSuccess={done} validate={validate} gapAbove={16}>
          { mode === 'success' && <Alert type="success" caption="The alias was successfully updated." /> }
          { mode === 'new' && <Alert type="success" caption="The alias was successfully created." /> }
          <FormErrorAlert />
          <TextField name="email" label="E-mail" readOnly copyShortcut />
          <TextField name="created" label="Created" readOnly />
          <DropList name="status" label="Status" meta={statusMeta} />
          <TextField name="dest" label="Destination" placeholder="Example: bob, keith" maxLength={80}
                     autoCapitalize="none" autoCorrect="off" autoComplete="off" spellCheck="false" />
          <TextField name="site" label="Site" type="url" placeholder="Example: amazon.com" maxLength={256} />
          <TextField name="siteUser" label="Site User" type="url" placeholder="Example: jondoe123"
                     maxLength={256} autoCapitalize="none" autoCorrect="off" autoComplete="off"
                     spellCheck="false" copyShortcut/>
          <PasswordField name="sitePassword" label="Site Password" placeholder="Example: z...x" maxLength={256} allowShow
                         autoCapitalize="none" autoCorrect="off" autoComplete="off" spellCheck="false" copyShortcut />
          <TextAreaField name="comment" label="Notes" rows={4} />
          <ButtonGroup justify='center'>
            <SubmitButton label="Save Changes" disabled={mode !== 'edit'} />
            <Button type="danger" onClick={handleDelete}>Delete Alias</Button>
            <Button type='secondary' onClick={returnToList}>Return to List</Button>
          </ButtonGroup>
        </Form>
      </Section>
    </Sections>
  );
}

function Creator()
{
  const [ aliases ] = useAliasList();
  const [ data, setData ] = useState(() => ({ key: "ord*", dest: Username.compress(session.username),
                                              site: "", siteUser: "", sitePassword: "", comment: "" }));

  const validate = (data, error) =>
  {
    if (/^[a-z0-9_-]+\*?$/.exec(data.key) === null)
      error('key', "The key must not be empty and may contain lowercase letters, numbers, '_', and '-'.");
    if (aliases.find(alias => alias.key === data.key))
      error('key', "An alias with that key already exists");
    if (data.dest === "")
      error('dest', "One or more names must be specified");
  };

  const submit = data => createAlias(data);
  const done = result => navigate(`/aliases/${result.key}`, { replace: true, state: { brandNew: true } });
  const returnToList = () => navigate('/aliases');

  return (
    <Sections>
      <Section icon={addAliasIcon} title="New Alias">
        <Form data={data} onChange={data => setData(data)} submit={submit} onSuccess={done} validate={validate} gapAbove={16}>
          <FormErrorAlert />
          <TextField name="key" label="Key" placeholder="Example: ord*" maxLength={80}
                     autoCapitalize="none" autoCorrect="off" autoComplete="off" spellCheck="false" />
          <TextField name="dest" label="Destination" placeholder="Example: bob, keith" maxLength={80}
                     autoCapitalize="none" autoCorrect="off" autoComplete="off" spellCheck="false" />
          <TextField name="site" label="Site" type="url" placeholder="Example: amazon.com" maxLength={256} />
          <TextField name="siteUser" label="Site User" type="url" placeholder="Example: jondoe123"
                     maxLength={256} autoCapitalize="none" autoCorrect="off" autoComplete="off"
                     spellCheck="false" copyShortcut/>
          <PasswordField name="sitePassword" label="Site Password" placeholder="Example: z...x" maxLength={256} allowShow
                         autoCapitalize="none" autoCorrect="off" autoComplete="off" spellCheck="false" />
          <TextAreaField name="comment" label="Notes" rows={4} />
          <ButtonGroup justify='center'>
            <SubmitButton label="Create Alias" />
            <Button type='secondary' onClick={returnToList}>Cancel</Button>
          </ButtonGroup>
        </Form>
      </Section>
    </Sections>
  );
}

function MissingAliasAlert({ aliasKey })
{
  return (
    <div styleName="alert">
      <Alert type="info" caption="Alias Not Found">
        <p>The alias (<b>{aliasKey}</b>) does not exist. Perhaps it was deleted?</p>
        <ButtonGroup>
          <Button type="secondary" onClick={() => navigate('/aliases')}>Return to List</Button>
        </ButtonGroup>
      </Alert>
    </div>
  );
}

function editableAlias(alias)
{
  return { ...alias, created: `${Timestamp.of(alias.created).longString} by ${alias.creator}` };
}
