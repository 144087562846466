/** @module chassis */

import React from 'react';
import ReactDOM from 'react-dom';
import Paper, { SearchRoot } from '@ocsoft/paper';
import { AppContext } from './app-context.js';

import './reset.css';
import styles from './app.css';

// -----------------------------------------------------------------------------
//    Application
// -----------------------------------------------------------------------------

/**
 * A top-level application component.
 *
 * This component initializes {@link :paper.Paper}, establishes a {@link :paper.SearchRoot}, and can
 * enable various optional features.
 *
 * The {@link :.render} function is used to render an application.
 *
 * @prop {boolean} [withFaults=false] - Whether the associated server provides a fault list.
 * @prop {boolean} [withAuthentication=false] - Whether the associated server may require a username and password
 *   for some or all requests.
 * @prop {:paper~Element} children - The application's render tree.
 * @component
 */
export function Application({ withFaults=false, withAuthentication=false, children })
{
  return (
    <Paper>
      <SearchRoot>
        <AppContext.Provider value={{ withFaults, withAuthentication }}>
          { children }
        </AppContext.Provider>
      </SearchRoot>
    </Paper>
  );
}

/**
 * Renders an application in the `document` element with ID `'main'`.
 *
 * @param {:.Application} app - The application.
 *
 * @example
 * render(
 *   <Application withFaults>
 *     <PageContent>
 *       Hello, world.
 *     </PageContent>
 *   </Application>
 * );
 */
export function render(app)
{
  ReactDOM.render(app, document.getElementById('main'));
}
