import { create } from '@ocsoft/sync-object';
import { useSyncList, useSyncListItem } from '@ocsoft/sync-react';
import { useSearch } from '@ocsoft/paper';

// -----------------------------------------------------------------------------
//    Hooks
// -----------------------------------------------------------------------------

export function useAliasList()
{
  return useSyncList('/api/aliases');
}

export function useSearchableAliasList()
{
  const found = useSearch();
  return useSyncList('/api/aliases', alias => found(alias.key) || found(alias.site) || found(alias.comment), [ found ]);
}

export function useAlias(key)
{
  return useSyncListItem('/api/aliases', alias => alias.key === key, [ key]);
}

// -----------------------------------------------------------------------------
//    Utilities
// -----------------------------------------------------------------------------

export function createAlias(props)
{
  return create('/api/aliases', props);
}
