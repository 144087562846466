/** @module chassis */

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@ocsoft/paper';
import { useAppContext } from './app-context.js';
import { FaultPanel } from './fault-panel.js';
import { useAuthenticator } from './session.js';

import { faSpinner as busyIcon, faSignIn as loginIcon } from '@fortawesome/pro-solid-svg-icons';

import styles from './page-content.css';

// -----------------------------------------------------------------------------
//    PageContent
// -----------------------------------------------------------------------------

/**
 * A top-level wrapper for an application page.
 *
 * This component should appear at or near the root of every application page. It does the following:
 *
 * * Adds padding to ensure that the top of the page is below the fixed header bar.
 *
 * * If the {@link :.Application} ancestor's `withFaults` property is set to `true`, a fault panel is placed
 *   at the top of the page. The fault panel is hidden unless the server is currently reporting at least one fault.
 *
 * * If the `Application` ancestor's `withAuthentication` property is set to `true`, machinery is put in place to
 *   prompt for a username and password whenever the server indicates that a login is required.
 *
 * @prop {:paper~Element} children - The page's components.
 * @component
 */
export function PageContent({ children })
{
  const { withFaults, withAuthentication } = useAppContext();

  return (
    <div styleName="root">
      { withFaults && <FaultPanel /> }
      { withAuthentication ? <AuthenticatedContent>{ children }</AuthenticatedContent> : children }
    </div>
  );
}

function AuthenticatedContent({ children })
{
  const { status, code, message, authenticate } = useAuthenticator();

  if (status === 'idle')
    return children;

  return (
    <LoginPrompt status={status} code={code} message={message} onLogin={authenticate}  />
  );
}

function LoginPrompt({ status, code, message, onLogin })
{
  const [ username, setUsername ] = useState("");
  const [ password, setPassword ] = useState("");
  const ready = status === 'prompt' && username !== "" && password !== "";

  const login = () => onLogin(username, password);

  const keyDown = evt =>
  {
    if (evt.key === 'Enter')
    {
      evt.preventDefault();
      if (ready)
        login();
    }
  };

  useEffect(() =>
  {
    if (status === 'prompt')
      setPassword("");
  }, [ status ]);

  return (
    <div styleName={status === 'prompt' && code !== 'unauth' ? 'login-error' : 'login-normal'} onKeyDown={keyDown}>
      <div styleName="top">
        <div styleName="message">{ message }</div>
        <div styleName="icons">
          { status === 'login' && <FontAwesomeIcon icon={busyIcon} spin /> }
          { status === 'prompt' && <IconButton icon={loginIcon} disabled={! ready} onClick={login} /> }
        </div>
      </div>
      <InputBox type='text' text={username} placeholder="Enter your username" onChange={evt => setUsername(evt.target.value)}
                autoFocus disabled={status === 'login'} />
      <InputBox type="password" text={password} placeholder="Enter your password" onChange={evt => setPassword(evt.target.value)}
                disabled={status === 'login'} />
    </div>
  );
}

function InputBox({ type='text', text, placeholder, onChange, ...props })
{
  return (
    <div styleName='box'>
      <input type={type} styleName='input' value={text} onChange={onChange} placeholder={placeholder} { ...props } />
    </div>
  );
}
